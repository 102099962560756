.socialItems {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footerWrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .footerWrapper {
    flex-direction: column;
  }
}
