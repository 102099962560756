.typologyTab {
    max-width: 684px;
}

.items-container {
    display: flex;
}

.item {
    background-color: #000;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 150px;
}

.item-selected {
    background-color: #e7e7e7;
    border: 1px solid #777777;
    border-bottom: 0;
    height: 155px;
}

.item-selected > p {
    color: #000 !important;
}

.item:first-child,
.item:nth-last-child(2) {
    margin-right: 5px;
}

.item > img {
    align-self: center;
    margin: 0;
    padding: 0;
    width: 150px;
    height: 70px;
    object-fit: cover;
}

.item > p {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-top: 1em;
    color: #fff;
    font-size: 1.3em;
}

.text-container {
    background-color: #e7e7e7;
    padding: 28px 37px;
    margin-top: -0.1em;
    display: flex;
    justify-content: center;
    border: 1px solid #777777;
    flex-direction: column;
}

.text-container > p {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
}

@media screen and (max-width: 940px) {
    .typologyTab {
        display: none;
    }
}
