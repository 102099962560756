@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot?m4c4wa');
  src: url('./icomoon.eot?m4c4wa#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?m4c4wa') format('truetype'),
    url('./icomoon.woff?m4c4wa') format('woff'),
    url('./icomoon.svg?m4c4wa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: '\e905';
}
.icon-instagram:before {
  content: '\e900';
}
.icon-youtube:before {
  content: '\e901';
}
.icon-facebook:before {
  content: '\e902';
}
.icon-search:before {
  content: '\e903';
}
.icon-arrow:before {
  content: '\e904';
}
