.main {
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 45px);
    flex-direction: column;
}
.header {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 24px;
}
.header strong {
    font-weight: 700;
}
.title {
    color: #000;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 20px 0;
}
.item {
    display: block;
    color: #707070;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
}
.item_title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 3px;
    text-decoration: underline;
}
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606060;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 40px;
}
.footer .icon {
    color: #c6c6c6;
    font-size: 20px;
    padding: 0 10px;
    margin: 0 8px;
}
.footer .icon:first-child {
    transform: rotate(180deg);
}
.form {
    position: static;
    height: auto;
    background-color: #e4002b;
    padding: 28px 0;
    opacity: 1;
    z-index: 1;
    visibility: visible;
}
.form_header {
    text-align: center;
    margin-bottom: 15px;
}
.form_title {
    max-width: 600px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 auto;
}
.form_field {
    text-align: center;
}
.form_input {
    margin-bottom: 20px !important;
}
@media screen and (min-width: 1024px) {
    .main {
        min-height: 0;
        flex-direction: column-reverse;
    }
    .header {
        margin-bottom: 30px;
    }
    .title {
        margin-top: 32px;
        margin-bottom: 24px;
    }
    .item {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .item_title {
        font-size: 18px;
        line-height: 24px;
    }
    .footer {
        justify-content: flex-end;
    }
    .form {
        padding: 40px 0 50px;
    }
    .form_title {
        text-align: left;
        text-transform: none;
    }
    .form_input {
        margin-bottom: 0 !important;
    }
    .form_submit {
        position: absolute;
        top: 0;
        right: 0;
    }
}
