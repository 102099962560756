.body {
  text-align: center;
}

.body > .title {
  font-size: 1.7em;
  font-weight: bold;
}

.body > .descr {
  font-size: 1.5em;
}

.body > h2 {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 2em;
  font-size: 2em;
  color: #212121;
}

.icon {
  margin: 3em 0;
}
