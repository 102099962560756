select {
    height: 25px;
    text-transform: uppercase;
    font-family: 'Roboto';
    color: #535353;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */
    outline: 0;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    flex: 1;
    margin: 1em;
}

option::last-word {
    font-weight: 700;
}

option {
    text-transform: uppercase;
    font-family: 'Roboto';
    color: #535353;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
}
