.headerInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

.headerInfoContainer h3 {
  color: #e40044;
  font-weight: bold;
  font-size: 1.2em;
}

.headerInfoContainer h1 {
  color: #e40044;
  font-weight: bold;
  font-size: 2em;
  text-transform: uppercase;
  margin-right: 1em;
}

.category {
  color: #7f7f7f;
  font-weight: bold;
  font-size: 1em;
  padding-top: 0.5em;
}

.links a {
  margin: 0 0.2em;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .headerInfoContainer {
    flex-direction: column;
  }
  .links {
    margin-top: 2em;
  }
}
