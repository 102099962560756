.address-list {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.address-list > li {
    margin-right: 1em;
}

.address-list p {
    margin: 0;
}
