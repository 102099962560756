.container {
  position: relative;
  cursor: pointer;
  max-height: 3em;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
  background-color: #fff;
  outline: 0;
  border: 1px solid #222222;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.opened {
  max-height: 1000em;
  transition: max-height 0.5s ease-in-out;
}

.container h2 {
  text-transform: uppercase;
  color: #262626;
  font-size: 1.2em;
}

.expand-icon {
  color: #262626;
  font-size: 12px;
}

.icon-container {
  width: 18px !important;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid #262626;
  position: absolute;
  right: 2em;
  cursor: pointer;
}
