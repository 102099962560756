.payment-types {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.payment-types p {
  margin: 0.5em 0.5em;
  font-size: 0.8em;
}

.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
}
