.info li {
    position: relative;
    color: #535353;
    font-size: 16px;
    line-height: 1.3;
    padding-left: 44px;
    margin-bottom: 20px;
    break-inside: avoid;
}
.info li:nth-child(1)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 22px;
    height: 30px;
    background-image: url('../../../../assets/img/pin.png');
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
}
.info li:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 28px;
    height: 28px;
    background-image: url('../../../../assets/img/clock.png');
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
}
.info li:nth-child(3)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 29px;
    height: 29px;
    background-image: url('../../../../assets/img/phone.png');
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
}
.info li:nth-child(4)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 28px;
    height: 19px;
    background-image: url('../../../../assets/img/mail.png');
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
}
@media screen and (min-width:1024px) {
    .info li {
        font-size: 18px;
    }
}