.qty {
  display: flex;
}

.qty > input {
  width: 3em;
  text-align: left;
  padding: 0.2em 0 0.2em 0.5em;
  font-weight: bold;
  font-size: 1em;
  color: #606060;
  border: 1px solid #cfcfcf;
}

.qty > a {
  text-transform: uppercase;
  font-size: 0.9em;
  border-bottom: 2px solid #878787;
  margin-bottom: 0.2em;
  color: #878787;
  margin-left: 0.5em;
}
