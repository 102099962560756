@import url('https://use.typekit.net/tcx2mts.css');
body {
    color: #606060;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
a,
a:hover {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
    font-weight: 600;
    margin: 0;
}
img {
    max-width: 100%;
    height: auto;
}
ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.l-page {
    overflow: hidden;
}
.l-container {
    padding-left: 20px;
    padding-right: 20px;
}
.l-container::before,
.l-container::after {
    content: ' ';
    display: table;
}
.l-container::after {
    clear: both;
}
p:first-of-type {
    margin-top: 0;
}
p:last-of-type {
    margin-bottom: 0;
}
iframe {
    width: 100%;
}
.alignleft {
    display: block;
    margin-bottom: 10px;
}
.alignright {
    display: block;
    margin-bottom: 10px;
}
.aligncenter {
    text-align: center;
}
.slick-slide:focus {
    outline: none;
}
input::-moz-placeholder {
    color: #606060;
    opacity: 1;
}
input:-ms-input-placeholder {
    color: #606060;
}
input::-webkit-input-placeholder {
    color: #606060;
}
.l-3col {
    font-size: 0;
    padding: 10px;
}
.l-3col > * {
    margin-bottom: 20px;
}
.l-4col {
    font-size: 0;
}
.l-4col > * {
    margin-bottom: 35px;
}
figure {
    font-size: 0;
    margin: 0;
}
.u-desktop {
    display: none;
}
/* PAGINA COMPONENTI */
.l-component-container {
    padding: 40px;
    border: 2px solid #e4002b;
    margin: 80px;
}
.l-component-container::before,
.l-component-container::after {
    content: ' ';
    display: table;
}
.l-component-container::after {
    clear: both;
}
.l-component-container__title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0 30px;
}
.l-component-container__variazione {
    color: red;
    font-size: 22px;
    font-weight: normal;
}
/* COMPONENTI */
.c-title {
    color: #e4002b;
    font-size: 22px;
    line-height: 1.25;
    text-transform: uppercase;
    font-family: 'century-gothic', sans-serif;
}
.c-subtitle {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-section-title {
    color: #e4002b;
    text-align: center;
    font-family: 'century-gothic', sans-serif;
    font-size: 24px;
    line-height: 46px;
    font-weight: 700;
    text-transform: uppercase;
}
.c-picture {
    width: 100%;
    font-size: 0;
    margin: 0;
    overflow: hidden;
}
.c-picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.c-button {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    background-color: #e4002b;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 5px 20px;
    border: 2px solid #e4002b;
    cursor: pointer;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
}
.c-button:disabled {
    background-color: #cdcdcd;
    border: 2px solid #fff;
    opacity: 0.5;
}
.c-button:hover:disabled {
    background-color: #cdcdcd;
    border: 2px solid #fff;
    cursor: wait;
    opacity: 0.5;
}
.c-button--long {
    min-width: 256px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    padding: 0 20px !important;
}
.c-button--ghost {
    color: #e4002b;
    background-color: transparent;
    font-weight: bold;
}
.c-button--light {
    color: #070707;
    background-color: #fff;
    border-color: #fff;
}
.c-button:hover {
    color: #fff;
}
.c-button--ghost:hover {
    color: #e4002b;
}
.c-button--light:hover {
    color: #fff;
    background-color: #e4002b;
    border-color: #e4002b;
}
.c-button--big {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0 20px !important;
}
.c-select-container {
    width: 100%;
    height: 35px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAALCAYAAACd1bY6AAAABGdBTUEAALGPC/xhBQAAAHVJREFUKBVjYGBgiANiViCmBID0xzECidVAzALEYUD8G4hJBSCDQGaA9YI466GYVBdi1YtVEGgBPoBXD15JNFOJUkuMImLUwO3GpxifHNwAdAY2TdjE0PXh5CNr5gaqIjfG4RbADHwFNQzEpwiADEgCYoIGAQB8DhriFBZfrAAAAABJRU5ErkJggg==')
        no-repeat right 15px center #fff;
    border: 1px solid #95989a;
    overflow: hidden;
}
.c-select {
    width: 100%;
    height: 35px;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    padding-left: 8px;
    padding-right: 35px;
    border: none;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.c-content {
    color: #535353;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 50px;
}
.c-content__header {
    margin-bottom: 20px;
}
.c-content--company .c-content__header .c-subtitle {
    max-width: 400px;
    color: #000000;
    text-align: center;
    font-family: 'century-gothic', sans-serif;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}
.c-content__text h1 {
    font-size: 2em;
    margin: 1em 0;
}
.c-content__text ul {
    list-style: initial;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
}
.c-content__text h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
}
.c-content__text h3 {
    font-size: 1.17em;
    margin: 0.5em 0;
}
.c-content__text h4 {
    font-size: 1em;
    margin: 0.5em 0;
}
.c-content__text--2col {
    margin-top: 15px;
}
.c-content__text::before,
.c-content__text::after {
    content: ' ';
    display: table;
}
.c-content__text::after {
    clear: both;
}
.c-content-video {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.c-content--picture .c-content__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-content--picture .c-content__text {
    font-size: 16px;
}
.c-content__figure {
    margin-bottom: 15px;
}
.c-content__footer {
    display: block;
    text-align: center;
    margin-top: 20px;
}
.l-1col .c-content::after {
    content: '';
    display: block;
    max-width: 1000px;
    height: 1px;
    background-color: #95989a;
    margin: 0 50px;
    margin-top: 50px;
}
.c-gallery__figure img {
    width: 100%;
}
.c-gallery__figure {
    margin-bottom: 20px;
}
.c-gallery {
    font-size: 0;
}
.c-card {
    display: block;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
}
.c-card__figure {
    width: 100%;
    height: 60vw;
}
.c-card__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-card__content {
    color: #535353;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #95989a;
    margin: 10px 20px 0;
    margin-top: 10px;
}
.c-card__content__title {
    color: #e4002b;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.c-card__footer {
    color: #e4002b;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 20px;
}
/* HEADER */
.c-site-header {
    position: relative;
    color: #fff;
    background-color: #000;
    font-size: 16px;
    padding: 10px 0;
}
.c-site-header__logo {
    display: inline-block;
    vertical-align: bottom;
    max-width: 220px;
    font-size: 0;
    margin-right: 10px;
}
.c-site-header__logo--product {
    display: none;
}
.c-site-header__actions__item {
    display: block;
    text-align: center;
    padding: 10px 0;
}
.c-site-header__actions__item__cart {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    color: rgb(255, 255, 255);
    background-color: #e4002b;
    font-size: 12px;
    border-radius: 12px;
    transform: translateX(-50%);
}
.c-site-header__actions .icon {
    font-size: 20px;
    font-weight: normal;
    text-transform: none;
}
.j-login-open .c-site-header__actions .j-login {
    color: #e4002b;
}
.j-search-open .c-site-header__actions .icon-search {
    color: #e4002b;
}
.c-site-header__toggle {
    position: absolute;
    top: 12px;
    right: 20px;
}
.c-site-header__toggle__item {
    display: block;
    width: 25px;
    border-top: 2px solid #fff;
    border-radius: 10px;
    margin: 4px 0 4px auto;
}
.c-site-header__close {
    position: fixed;
    top: 12px;
    right: 20px;
    display: none;
    color: #fff;
    font-size: 18px;
    padding: 0 6px;
    z-index: 999;
}
.c-site-header__close:hover {
    color: #fff;
}
.j-menu-open .c-site-header__close,
.j-search-open .c-site-header__close {
    display: block;
}
/* MENU HEADER */
.c-site-header__nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    text-transform: uppercase;
    overflow: auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 10;
}
.j-menu-open .c-site-header__nav {
    height: auto;
    padding: 50px 0;
}
.c-site-header__menu {
    list-style: none;
    padding: 0;
    margin: 0;
}
.c-site-header__menu__item__picture {
    display: none;
    font-size: 0;
    margin: 0;
}
.c-site-header__menu__item > a {
    position: relative;
    display: block;
    text-align: center;
    padding: 10px 0;
}
.c-dropdown-menu {
    display: none;
    background-color: #000;
    padding: 10px 0 20px;
    z-index: 999999;
}
.c-menu-list {
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}
.c-menu-list__title {
    font-weight: normal;
    margin-bottom: 5px;
}
.c-menu-list__title > a {
    display: block;
    padding: 3px 25px;
}
.c-menu-list__item {
    display: none;
    color: #808080;
    font-weight: normal;
}
.c-menu-list__item > a {
    display: block;
    padding: 3px 25px;
}
/* COMPORTAMENTO MENU */
.j-menu-open:not(.j-submenu-open) .c-site-header__toggle,
.j-search-open .c-site-header__toggle {
    display: none;
}
.j-submenu-open .c-site-header__close {
    display: none;
}
.j-submenu-open .c-site-header__nav {
    top: 45px;
}
.c-site-header__menu__item.j-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    padding-top: 50px;
    z-index: 11;
}
.c-site-header__menu__item.j-active > a {
    padding-top: 0;
}
.c-site-header__menu__item.j-active > a::before {
    content: '';
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    height: 25px;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(transparent),
        to(#000)
    );
    background-image: linear-gradient(transparent, #000);
}
.c-site-header__menu__item.j-active .c-dropdown-menu {
    display: block;
}
.c-site-header__menu__item.j-active .c-site-header__menu__item__picture {
    display: block;
}
/* FORM */
.c-form {
    font-size: 0;
}
.c-form a,
.c-form p,
.c-form span {
    font-size: 12px;
}
.c-form fieldset {
    width: 100%;
    padding: 0;
    border: 0;
    margin: 0;
}
.c-form__header {
    text-align: center;
    margin-bottom: 30px;
}
.c-form__title {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
}
.c-form__field {
    text-align: left;
    margin-bottom: 20px;
}
.c-form__field__label {
    font-size: 12px;
    text-transform: uppercase;
}
.c-form__field__input {
    display: block;
    width: 100%;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 19px;
    padding: 8px;
    border: 1px solid #95989a;
}
.c-form__field--checkbox {
    position: relative;
}
.c-form__field--checkbox input {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.c-form__field--checkbox span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #95989a;
    z-index: 0;
}
.c-form__field--checkbox .c-form__field__label {
    line-height: 20px;
    text-transform: none;
    padding-left: 26px;
}
.c-form__field--checkbox input[type='checkbox']:checked + span::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 9px;
    height: 5px;
    background: transparent;
    border: 2px solid #000;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.c-form__footer {
    text-align: center;
}
.c-form__footer a {
    display: inline-block;
    border-bottom: 1px solid #95989a;
}
.c-form .c-form-link {
    display: inline-block;
    vertical-align: middle;
    color: #e4002b;
    font-size: 11.5px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #95989a;
}
.c-form-link:hover {
    color: #e4002b;
    border-color: #e4002b;
}
.c-distribution-form fieldset {
    position: relative;
    padding: 0;
    border: none;
    margin: 0;
}
/* FORM INLINE */
.c-form--inline .c-form__field__input {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    border: 1px solid #e4002b;
    margin-bottom: 0;
}
.c-form--inline .c-button {
    width: 30%;
}
/* SEARCH + LOGIN */
.c-form--search,
.c-form--login {
    position: fixed;
    top: 45px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    padding: 50px 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    z-index: 10;
    visibility: hidden;
}
.j-search-open .c-form--search,
.j-login-open .c-form--login {
    height: auto;
    opacity: 1;
    visibility: visible;
}
.c-form-close {
    display: block;
    float: right;
}
.c-form--login {
    color: #606060;
    background-color: #fff;
    text-align: center;
}
.c-form--login .c-form__footer {
    position: relative;
    text-align: right;
    padding-bottom: 110px;
    border-bottom: 1px solid #95989a;
    margin-bottom: 20px;
}
.c-form--login .c-form__footer .c-button {
    position: absolute;
    left: 50%;
    bottom: 35px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.c-form--search {
    background-color: #e4002b;
    background-color: rgba(228, 0, 68, 0.85);
}
.c-form--search .c-form__field {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
}
.c-form--search .c-form__field__input {
    background-color: transparent;
    border: 2px solid #fff;
    margin-bottom: 40px;
}
.c-form--search .c-form__field .icon {
    display: none;
}
.c-form--search .c-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
    padding: 0;
    border-color: #fff;
    border-radius: 50%;
}
.c-form--search .c-button span:not(.icon) {
    display: none;
}
.c-form--search .c-button .icon {
    display: block;
    font-size: 24px;
    font-weight: normal;
    text-transform: none;
}
/* HERO */
.c-hero {
    background-color: #000;
    padding-top: 20px;
    padding-bottom: 20px;
}
.c-hero__picture {
    position: relative;
    font-size: 0;
    margin: 0 -20px;
}
.c-hero__picture::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(36%, rgba(0, 0, 0, 0)),
        color-stop(72%, rgba(0, 0, 0, 0)),
        color-stop(89%, rgba(0, 0, 0, 0)),
        color-stop(100%, black)
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(36%, rgba(0, 0, 0, 0)),
        color-stop(72%, rgba(0, 0, 0, 0)),
        color-stop(89%, rgba(0, 0, 0, 0)),
        to(black)
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 36%,
        rgba(0, 0, 0, 0) 72%,
        rgba(0, 0, 0, 0) 89%,
        black 100%
    );
    z-index: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
}
.c-hero__picture img {
    width: 100%;
}
.c-hero__title {
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
}
.c-hero__title span {
    margin-left: 20px;
}
.c-hero__subtititle {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 5px;
}
.c-hero__before-title {
    color: #e40043;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    margin: 2em 0 0em 0;
}
.c-hero--fullwidth {
    position: relative;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
}
.c-hero--fullwidth .c-hero__title {
    position: absolute;
    left: 50%;
    bottom: 30px;
    width: 90%;
    max-width: 400px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
}
.c-hero--fullwidth::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(48%, rgba(0, 0, 0, 0.55)),
        color-stop(87%, rgba(0, 0, 0, 1)),
        color-stop(100%, rgba(0, 0, 0, 1))
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0)),
        color-stop(48%, rgba(0, 0, 0, 0.55)),
        color-stop(87%, rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 1))
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.55) 48%,
        rgba(0, 0, 0, 1) 87%,
        rgba(0, 0, 0, 1) 100%
    );
    z-index: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",
            endColorstr="#000000",
            GradientType=0);
}
.l-main .c-hero--fullwidth::before {
    display: none;
}
.c-hero--fullwidth .c-hero__picture {
    margin: 0;
}
.c-hero--fullwidth .c-hero__picture::before {
    display: none;
}
.c-hero--fullwidth .c-hero__picture img {
    max-height: 400px;
    -o-object-fit: cover;
    object-fit: cover;
}
/*C-HERO--ACADEMY*/
.c-hero--academy {
    position: relative;
    min-height: 30vh;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
}
.c-hero--academy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e40043;
    mix-blend-mode: multiply;
}
.c-hero--academy .c-title {
    color: #f280a1;
    font-size: 37px;
    font-weight: 500;
    text-transform: uppercase;
}
.c-hero--academy .c-subtitle {
    color: #ffffff;
    font-family: 'century-gothic', sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 30px;
}
.c-hero--academy .c-hero__container {
    position: relative;
    z-index: 1;
}
.c-hero--city {
    color: #fff;
    background-color: #e40043;
}
.c-hero--city .c-title {
    position: relative;
    top: auto;
    max-width: 100%;
    color: #fff;
    text-align: center;
    -webkit-transform: none;
    transform: none;
}
.c-hero__detail img {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 55px;
}
.c-hero__detail {
    text-align: center;
}
.c-hero__detail-text {
    display: inline-block;
    text-align: left;
}
.c-hero__detail-text > * {
    display: block;
    text-align: left;
    font-style: italic;
}
.c-hero__detail-title {
    font-size: 24px;
    font-style: inherit;
}
.c-hero--academy-small {
    position: relative;
    min-height: 220px;
}
.c-hero--academy-small .c-hero__title {
    position: absolute;
    bottom: 30px;
    max-width: 100%;
    font-size: 32px;
    z-index: 2;
}
.c-hero--academy-small::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e50043;
    mix-blend-mode: multiply;
}
.c-hero--academy-small::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0)),
        to(rgba(13, 13, 13, 1))
    );
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(13, 13, 13, 1) 100%
    );
}
.c-category-nav {
    display: none;
}
.c-slide {
    position: relative;
    display: block;
    height: 50vh;
    min-height: 280px;
    overflow: hidden;
}
.c-slide .c-picture {
    height: 100%;
}
.c-slide__cta {
    position: absolute;
    top: -10px;
    left: -20px;
    right: 0;
    background-color: #000;
    text-transform: uppercase;
    padding-top: 14px;
    padding-left: 25px;
    padding-bottom: 10px;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
    z-index: 2;
}
.c-slide__cta__title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 5px;
}
.c-slide__cta__date {
    color: #e4002b;
    font-size: 14px;
    font-weight: 600;
}
.c-slide__article {
    position: absolute;
    top: 0;
    left: 35%;
    right: 0;
    bottom: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 30px 15px;
    z-index: 1;
}
.c-slide__article__picture {
    width: 80%;
    max-width: 180px;
    font-size: 0;
    margin: 0 auto;
    margin-bottom: 20px;
}
.c-slide__article__picture img {
    display: inline-block;
}
.c-slide__article__text {
    max-width: 450px;
    margin: 0 auto;
}
.c-slide__article__text.center {
    max-width: none;
}
.c-slide__article__text .c-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.c-slide__article__text .c-subtitle {
    display: block;
    color: #fff;
    margin-top: 20px;
}
.c-slide__article__text .c-subtitle a {
    color: #e4002b;
    text-decoration: none;
}

/*SLIDER*/
.slick-arrow {
    position: absolute;
    top: 50%;
    color: #e4002b;
    background: none;
    font-size: 50px;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}
.slick-truccheria-prev {
    left: 40px;
    -webkit-transform: translateY(-50%) rotateY(180deg);
    transform: translateY(-50%) rotateY(180deg);
}
.slick-truccheria-next {
    right: 40px;
}
.c-slider {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 45px;
    margin-bottom: 35px;
}
.c-slider__item {
    padding-right: 15px;
}
.c-slider__item img {
    width: 100%;
}
.c-slider .slick-truccheria-arrow {
    top: auto;
    bottom: 0;
    font-size: 30px;
    -webkit-transform: none;
    transform: none;
}
.c-slider .slick-truccheria-prev {
    left: 15px;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.c-slider .slick-next {
    right: 15px;
}
.c-breadcrumbs {
    display: none;
}
.c-breadcrumbs.white > div a {
    color: #fff !important;
}
/* MENU */
.c-menu-item {
    position: relative;
    display: block;
    overflow: hidden;
}
.c-menu-item .c-picture {
    height: 250px;
}
.c-menu-item .c-title {
    background-color: #fff;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
}
/* CTA */
.c-cta {
    position: relative;
    display: block;
}
.c-cta__picture {
    width: 100%;
    margin: 0;
}
.c-cta__picture img {
    display: block;
    width: 100%;
    min-height: 340px;
    object-fit: cover;
}
.c-cta__picture img.desktop {
    display: none;
}
.c-cta__text {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 30px;
    text-align: center;
}
.c-cta .c-title {
    color: #fff;
    margin-bottom: 15px;
}
.c-cta .c-subtitle {
    color: #fff;
    margin-bottom: 20px;
}
/* BLOG */
.c-blog-item {
    position: relative;
    display: block;
    height: 40vh;
}
.c-blog-item .c-picture {
    height: 100%;
}
.c-blog-item .c-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 20px;
}
.c-blog-item__overlay {
    display: none;
}
/* PRODOTTI */
.l-products {
    font-size: 0;
    overflow: hidden;
}
.l-products:last-of-type {
    padding-bottom: 60px;
}
#category .l-products {
    padding-bottom: 60px;
}
.c-products-header {
    width: 100%;
    margin-top: 10px;
}
.c-products-header .c-title,
.c-content__header .c-title {
    color: #484848;
    text-align: center;
}
.c-products-header .c-subtitle,
.c-content__header .c-subtitle {
    color: #606060;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.c-product-item {
    position: relative;
    display: block;
    color: #484848;
    font-size: 16px;
    margin-top: 50px;
}
.c-product-item > a {
    display: block;
}
.c-product-item__picture {
    position: relative;
    height: 0;
    font-size: 0;
    padding-bottom: 90%;
    margin: 0;
    margin-bottom: 15px;
}
.c-product-item__picture img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.c-product-item .c-title {
    color: #484848;
    text-align: center;
    font-size: 18px;
    margin-bottom: 5px;
}
.c-product-item .c-subtitle {
    color: #606060;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.c-product-item p {
    color: #808080;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
}
.c-product-item__footer {
    text-align: center;
    margin-top: 10px;
}
.c-product-item .c-button {
    background-color: #c4c4c4;
    font-size: 18px;
    font-weight: 600;
    border-color: #c4c4c4;
}
.c-products-list {
    text-align: center;
    font-size: 0;
    list-style: none;
    padding: 10px 30px 0;
    margin: 0;
}
.c-products-list__item {
    display: inline-block;
    vertical-align: top;
    color: #808080;
    font-size: 15px;
    text-transform: uppercase;
}
.c-products-list__item::after {
    content: '-';
    margin: 0 5px;
}
.c-products-list__item:last-of-type::after {
    display: none;
}
/* PRODOTTO */
.c-header-product {
    display: none;
}
.c-product {
    padding-top: 25px;
    margin-bottom: 40px;
    overflow: hidden;
}
.c-product::after {
    content: '';
    display: block;
    max-width: 1000px;
    height: 1px;
    background-color: #95989a;
    margin: 0 50px;
    margin-top: 40px;
}
.c-product__header {
    position: relative;
    font-size: 16px;
    margin-bottom: 20px;
}
.c-product__header .c-title {
    color: #606060;
    padding-right: 60px;
    margin-bottom: 5px;
}
.c-new {
    position: absolute;
    color: #fff;
    background-color: #e4002b;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}
.c-product__header .c-new {
    top: -60px;
    right: -70px;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.c-product__picture {
    margin: 0;
    margin-bottom: 30px;
}
.c-product__description .c-select-container {
    margin-bottom: 30px;
}
.c-color-picker {
    margin-bottom: 30px;
}
.c-color {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
    -webkit-transition: border 0.2s ease, box-shadow 0.2s ease;
    -webkit-transition: border 0.2s ease, -webkit-box-shadow 0.2s ease;
    transition: border 0.2s ease, -webkit-box-shadow 0.2s ease;
    transition: border 0.2s ease, box-shadow 0.2s ease;
    transition: border 0.2s ease, box-shadow 0.2s ease,
        -webkit-box-shadow 0.2s ease;
}
.c-color.selected {
    width: 26px;
    height: 26px;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 0 0 2.5px #666666;
    box-shadow: 0 0 0 2.5px #666666;
    margin: 7px;
}
.c-price {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
}
.c-price span {
    display: inline-block;
    vertical-align: bottom;
    font-size: 22px;
    font-weight: 600;
    margin-left: 20px;
}
.c-product__footer {
    text-align: center;
    font-size: 16px;
}
.c-product .c-button {
    background-color: #a5a5a5;
    border-color: #a5a5a5;
    margin-bottom: 10px;
}
/* PRODOTTO SLIDER */
.c-product-slider {
    padding-bottom: 100px;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: 60px;
}
.c-product-slider .c-product-item {
    position: relative;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
}
.c-product-slider .c-product-item .c-title {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5px;
}
.c-product-slider .c-new {
    top: -25px;
    left: -60px;
    font-size: 11px;
    padding-bottom: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.c-product-slider .slick-arrow {
    position: absolute;
    bottom: 0;
    width: 55px;
    height: 55px;
    color: #e4002b;
    background: none;
    text-align: center;
    font-size: 25px;
    line-height: 55px;
    padding: 0;
    border: 3px solid #e4002b;
    cursor: pointer;
    z-index: 1;
}
.c-product-slider .slick-truccheria-prev {
    right: 50%;
    -webkit-transform: translateX(-50%) rotateY(180deg);
    transform: translateX(-50%) rotateY(180deg);
}
.c-product-slider .slick-truccheria-next {
    left: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
}
.c-product-slider .slick-arrow.slick-disabled {
    color: #c6c6c6;
    border-color: #c6c6c6;
}
/* SERVICE */
.c-service-item {
    position: relative;
    margin-top: 20px;
    margin-bottom: 60px;
    clear: both;
}
.c-service-item::before,
.c-service-item::after {
    content: ' ';
    display: table;
}
.c-service-item::after {
    clear: both;
}
.c-service-item__picture {
    width: 42%;
    font-size: 0;
    margin: 0;
    margin-right: 18px;
    margin-bottom: 20px;
    float: left;
}
.c-service-item__header {
    margin-bottom: 20px;
}
.c-service-item .c-title {
    color: #000;
    font-size: 18px;
    margin-bottom: 5px;
}
.c-service-detail {
    font-size: 16px;
}
.c-service-detail h3,
.c-service-detail h4 {
    color: #535353;
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.c-service-detail__item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
}
.c-service-detail span,
.c-service-detail time {
}
.c-service-detail span.icon {
    margin-right: 10px;
}
.c-service-item .c-content__text {
    margin-bottom: 20px;
    clear: both;
}
.c-service-item__footer {
    text-align: center;
}
.c-service-item__footer .c-button {
    height: auto;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
}
.c-service-item__footer .c-button .icon {
    margin-right: 5px;
}
/* COMPANY */
.c-content--company {
    margin-top: 15px;
}
.c-content--company .c-content__header__figure {
    max-width: 350px;
    margin: 0 auto;
}
.c-content--company .c-content__header__figure img {
    width: 100%;
}
.c-content--makeup {
    position: relative;
    background-image: url('../../assets/img/hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 0;
}
.c-content--makeup .c-content__text {
    position: relative;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 300px;
    z-index: 1;
}
.c-content--makeup::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: black;
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, black),
        color-stop(21%, #040404),
        color-stop(97%, rgba(18, 18, 18, 0)),
        color-stop(100%, rgba(19, 19, 19, 0))
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(black),
        color-stop(21%, #040404),
        color-stop(97%, rgba(18, 18, 18, 0)),
        to(rgba(19, 19, 19, 0))
    );
    background: linear-gradient(
        to bottom,
        black 0%,
        #040404 21%,
        rgba(18, 18, 18, 0) 97%,
        rgba(19, 19, 19, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#131313", GradientType=0);
}
.c-content--filosofia {
    margin-top: 30px;
}
.c-content--filosofia .c-section-title {
    color: #000000;
}
.c-content--filosofia .l-4col {
    margin-top: 50px;
}
.c-filosofy-item {
    margin-bottom: 30px;
}
.c-filosofy-item__figure {
    width: 100%;
    height: 120px;
}
.c-filosofy-item__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-filosofy-item__content {
    color: #535353;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}
.c-filosofy-item strong {
    font-weight: 700;
    text-transform: uppercase;
}
.c-content--special {
    margin-top: 30px;
}
.c-content--special .u-align-left {
    margin-top: 10px;
    margin-bottom: 10px;
}
.c-content--rose {
    background-color: #e4002b;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
}
.c-content--rose .c-content__text {
    color: #fff;
}
.c-content--company {
    margin-bottom: 50px;
}
.c-content--special {
    margin-bottom: 60px;
}
.c-content--filosofia .c-hero--fullwidth {
    margin-top: 50px;
}
.c-content.c-content--makeup {
    margin-bottom: 0;
}
.c-content.c-content--rose {
    margin-bottom: 0;
}
.c-content.c-content--filosofia {
    margin-bottom: 0;
}
/* PARTNERSHIP*/
.c-logo-grid {
    text-align: center;
    font-size: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}
.c-logo-grid__logo {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    margin-right: 15px;
}
.c-logo-grid__logo {
    margin-bottom: 20px;
}
/* PARTNERSHIP_POST */
.c-post .c-content__text::before,
.c-post .c-content__text::after {
    content: ' ';
    display: table;
}
.c-post .c-content__text::after {
    clear: both;
}
.c-post .c-content {
    color: #535353;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.u-align-left {
    width: 100%;
}
.u-align-left img {
    width: 100%;
}
.u-align-right img {
    width: 100%;
}
.c-logo {
    position: relative;
    left: 50%;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.c-logo__figure {
    max-width: 250px;
}
.c-logo__figure img {
    width: 100%;
}
.c-post__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #95989a;
    margin-top: 30px;
    margin-bottom: 30px;
}
.c-post__footer {
    padding-top: 20px;
    border-top: 1px solid #95989a;
    margin-top: 30px;
    margin-bottom: 20px;
}
.c-post__footer__item {
    display: inline-block;
    vertical-align: top;
    color: #8e8e8e;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.c-post__footer__item::after {
    content: '|';
    margin: 10px;
}
.c-post__footer__item:last-child::after {
    display: none;
}
.c-post-related {
    background-color: #f2f2f2;
    padding-bottom: 30px;
}
.c-post-related .c-title {
    color: #535353;
    text-align: center;
    font-weight: 700;
}
.c-post-related__header {
    padding-top: 20px;
    margin-bottom: 10px;
}
.c-card--collaborazioni .c-card__content {
    padding-bottom: 5px;
    border: none;
    margin-top: 20px;
}
.c-card--collaborazioni {
    background-color: #f2f2f2;
    border: 1px solid #95989a;
    margin-bottom: 20px;
}
.c-card--collaborazioni .c-card__content__title {
    color: #2f2f2f;
}
/* DISTRIBUZIONE */
.c-distribution-search {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.6);
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.c-distribution-search .c-retailers {
    max-width: none !important;
}
.c-distribution-search__header {
    text-align: center;
    margin-bottom: 10px;
}
.c-distribution-search__header__title {
    color: #000000;
    font-family: 'century-gothic', sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}
.c-distribution-search__content {
    color: #535353;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}
.c-distribution-search .c-button {
    position: relative;
    left: 50%;
    height: 45px;
    background-color: #8b8b8b;
    padding: 10px 30px;
    border: 1px solid #95989a;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 10px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.c-distribution-search .c-form__field__input {
    background-color: #fff;
    border: 1px solid #95989a;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.c-distribution-search .c-form__field__input::-webkit-input-placeholder {
    color: #c6c6c6;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.c-distribution-search .c-form__field__input:-ms-input-placeholder {
    color: #c6c6c6;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.c-distribution-search .c-form__field__input::-ms-input-placeholder {
    color: #c6c6c6;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.c-distribution-search .c-form__field__input::placeholder {
    color: #c6c6c6;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.c-distribution-search--special {
    position: relative;
    top: initial;
    left: initial;
    width: 100%;
    background-color: #e4002b;
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-transform: none;
    transform: none;
}
.c-distribution-search--special .c-form__field__input {
    height: 45px;
}
.c-distribution-search--special .c-distribution-search__content {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
}
.c-distribuzione {
    position: relative;
    min-height: 600px;
}
.c-distribuzione__figure img {
    width: 100%;
    min-height: 600px;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-agenti-distributori {
    padding-top: 25px;
    counter-reset: section;
}
.c-agenti-distributori__header {
    text-align: center;
    margin-bottom: 20px;
}
.c-agenti-distributori__header:nth-of-type(2) {
    margin-top: 40px;
}
.c-agenti-distributori__header .c-title {
    font-size: 20px;
}
.c-agenti-distributori__list {
    text-align: left;
    font-size: 16px;
    line-height: 19px;
}
.c-agenti-distributori__list__item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
}
.c-agenti-distributori__list__item::before {
    content: '' counter(section);
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 22px;
    height: 30px;
    color: #fff;
    background-image: url('../../assets/icons/marker.png');
    background-size: cover;
    text-align: center;
    font-size: 0.9em;
    counter-increment: section;
}
.c-agenti-distributori__list__item strong {
    color: #000000;
    font-weight: 700;
}
.c-agenti-distributori__list__item__name {
    font-weight: 700;
}
.c-agenti-distributori__list__item > * {
    display: block;
}
.c-agenti-distributori__list__item__link-maps {
    font-size: 8px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 5px;
    text-decoration: underline;
}
.c-agenti-distributori__list__item__link-maps:hover {
    color: #e4002b;
    text-decoration: underline;
}
.c-agent-form {
    background-color: #dddddd;
    padding: 25px 15px;
}
.c-agent-form fieldset {
    font-size: 0;
    border: none;
}
.c-agent-form .c-form__field__input {
    border: none;
}
.c-form__field__input::-webkit-input-placeholder {
    color: #95989a;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-form__field__input:-ms-input-placeholder {
    color: #95989a;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-form__field__input::-ms-input-placeholder {
    color: #95989a;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-form__field__input::placeholder {
    color: #95989a;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-agent-form__footer {
    text-align: center;
}
.c-agent-form__footer .c-button {
    background-color: #8b8b8b;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid #95989a;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.c-distribution {
    margin-top: 30px;
    margin-bottom: 45px;
}
.c-distribution__header {
    margin-bottom: 20px;
}
.c-distribution__header__title {
    color: #000000;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.c-distribution-map .c-map {
    display: none;
}
.c-filter-container {
    flex-wrap: wrap;
}
.c-box-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3em 0px 2em;
}
.c-box-item {
    width: 50%;
    color: #fff;
    text-align: center;
    padding: 15px;
    border-right: 1px solid #fff;
    margin-bottom: 1em;
}
.c-box-item:last-child {
    border: none;
}
.c-box-item p {
    font-size: 14px;
}
/* PAGE */
.c-page-block {
}
.c-page-block--picture {
}
.c-page-header {
    background-color: #000;
    text-align: center;
    padding: 20px;
}
.c-page-title {
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
}
.c-page__picture {
    margin: 0;
    margin-bottom: 20px;
}
.c-page__picture img {
    display: block;
    width: 100%;
}
.c-page-block--picture .c-content__text {
    margin-bottom: 40px;
}
.c-page-block .c-contact {
    margin-bottom: 40px;
}
.c-contact__header {
    text-align: center;
    margin-bottom: 25px;
}
.c-contact__header__logo {
    margin-bottom: 20px;
}
.c-contact__header .c-title {
    color: #484848;
}
.c-page-block .c-map {
    margin-bottom: 40px;
}
.c-page-block .c-map iframe {
    height: 380px;
}
.c-page-block .l-container .c-map {
    margin-left: -20px;
    margin-right: -20px;
}
.c-contact-container {
    position: relative;
}
.c-video {
    width: 100%;
}
.c-contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.c-contact-list__item {
    color: #606060;
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 15px;
}
.c-contact-list__item .icon {
    display: inline-block;
    color: #e4002b;
    font-size: 30px;
    margin-right: 15px;
}
.c-retailers {
    width: 100% !important;
    max-width: 720px !important;
    margin-bottom: 60px !important;
}
.c-retailers__box {
    position: relative;
    display: none;
    text-align: center;
    font-size: 16px;
    padding: 20px 20% 80px;
    border: 2px solid #e4002b;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
}
.c-retailers__box .c-button {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 80px;
    height: 80px;
    color: #e4002b;
    background-color: #fff;
    text-align: center;
    font-size: 40px;
    line-height: 75px;
    border: 2px solid #e4002b;
    border-radius: 50%;
    -webkit-transform: translate(-50%, +50%);
    transform: translate(-50%, +50%);
}
.c-retailers__field::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 23px;
    height: 23px;
    background-image: url('../../assets/img/search.png');
    background-size: 100%;
    background-repeat: no-repeat;
}
.c-retailers .c-form--inline {
    display: none;
}
.c-retailers .c-form__field__input {
    font-size: 16px;
    padding-left: 55px;
    padding-right: 25px;
    border: 1px solid #e40043;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.c-retailers .c-form__field__input::-moz-placeholder {
    font-size: 16px;
    text-transform: none;
}
.c-retailers .c-form__field__input:-ms-input-placeholder {
    font-size: 16px;
    text-transform: none;
}
.c-retailers .c-form__field__input::-webkit-input-placeholder {
    font-size: 16px;
    text-transform: none;
}
.c-related {
    background-color: #e4002b;
    padding-bottom: 50px;
}
.c-related__header {
    background-color: #e4002b;
    text-align: center;
    padding: 30px 20px 0;
}
.c-related__header .c-title {
    color: #fff;
    font-size: 26px;
}
.c-related .c-product-item .c-title {
    color: #fff;
}
.c-related .c-product-item .c-button {
    color: #e4002b;
}
/*ACADEMY*/
/*C-CONTENT-BLOCK*/
.c-content-block {
    padding: 30px 0;
}
.c-content-block--rose {
    background-color: #e40043;
}
.c-content-block--rose-dark {
    background-color: #000000;
}
.c-content-block--dark {
    background-color: #000000;
}
.c-content-block--dark * {
    color: #fff;
}
.c-content-block--rose .c-title {
    color: #fff;
}
.c-content-block--grey .c-title {
    color: #000000;
}
.c-content-block--center {
    text-align: center;
}
.c-content-block__header {
    text-align: center;
    margin-bottom: 35px;
}
.c-content-block .c-content {
    margin-top: 25px;
}
.c-content__item > * {
    margin-bottom: 20px;
}
.c-title--black {
    color: #000000;
}
.c-title--big {
    font-size: 36px;
}
.c-title--small {
    font-size: 20px;
}
.c-content-block__footer {
    text-align: center;
    margin-top: 50px;
}
/*C-EXCERPT*/
.c-excerpt {
    display: inline-block;
    vertical-align: top;
    background-color: #000;
    border: 5px solid #000000;
}
.c-excerpt__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-excerpt__content {
    color: #fff;
    background-color: #000;
    padding: 20px 30px 30px 30px;
}
.c-excerpt__text {
    font-family: 'Roboto', sans-serif;
}
.c-excerpt__title {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 18px;
}
.c-excerpt__footer {
    text-align: center;
    margin-top: 25px;
}
.c-excerpt__footer .c-button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
/*C-ACADEMY-SEARCH*/
.c-academy-search__header {
    position: relative;
    height: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
}
.c-academy-search__header-text::before {
    content: '';
    position: absolute;
    left: -30px;
    bottom: -15px;
    width: 100vw;
    height: 100vw;
    background-color: rgba(255, 255, 255, 0.55);
    z-index: 0;
}
.c-academy-search__header-text {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 15px;
    text-align: center;
}
.c-academy-search__title {
    position: relative;
    color: #000000;
    font-family: 'century-gothic', sans-serif;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
}
.c-academy-search__subtitle {
    position: relative;
    color: #000000;
    font-size: 20px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
}
.c-form--filter-academy .c-form__field {
    margin-bottom: 0;
}
.c-form--filter-academy .c-select-container {
    height: 60px;
}
.c-form--filter-academy .c-select {
    display: block;
    width: 100%;
    /* width: 150px; */
    height: 60px;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto;
}
.c-content-block--rose .c-downloads {
    margin-top: 30px;
}
.c-downloads .c-button--ghost {
    display: block;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
    border-color: #fff;
    margin-bottom: 25px;
}
.c-content-block--rose .c-downloads .c-button--ghost:last-of-type {
    margin-bottom: 0;
}
.c-where {
    margin-top: 35px;
}
/*BUTTON--WHERE*/
.c-button--where {
    display: block;
    height: auto !important;
    color: #535353;
    background-color: #f2f2f2;
    text-align: center;
    font-size: 20px !important;
    padding: 20px !important;
    border: 1px solid #95989a;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-left: 0;
    margin-bottom: 20px;
}
.c-button--where.u-mobile {
    position: relative;
    display: block;
    height: auto !important;
    color: #000000;
    text-align: left;
    font-size: 16px !important;
    padding: 20px 40px !important;
    border: none;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0;
}
.c-button--where.u-mobile:nth-of-type(even) {
    background-color: #fff;
}
.c-button--where span {
    display: block;
    text-transform: none;
    margin-bottom: 5px;
}
.c-button--where.u-mobile strong {
    position: absolute;
    top: 50%;
    right: 40px;
    color: #ffffff;
    background-color: #c4c4c4;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.c-table {
    font-size: 16px;
    border-collapse: collapse;
}
.c-table--big {
    position: relative;
    left: 50%;
    font-size: 11px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.c-table td,
.c-table th {
    padding: 4px 30px;
    border: 1px solid #dedede;
}
.c-table--big td,
.c-table--big th {
    padding: 4px 10px;
}
.c-table th {
    font-size: 14px;
}
.c-table:not(.c-table--big) td:nth-of-type(2),
.c-table:not(.c-table--big) th:nth-of-type(2) {
    padding: 4px 20px;
}
.c-table thead th {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-table--big thead th {
    font-size: 10px;
}
.c-table td small {
    display: block;
    color: #838383;
    font-size: 6px;
}
.c-table--big td:not(:first-of-type) {
    text-align: center;
}
.c-list {
    font-size: 14px;
}
.c-list li::before {
    content: '-';
}
.c-course {
    position: relative;
    text-align: left;
    padding-left: 140px;
    margin-bottom: 35px;
}
.c-course__figure {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 120px;
}
.c-course__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-course__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
.c-course__lead {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}
.c-course__date {
    display: block;
    color: #535353;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
}
.c-course__button {
    display: inline-block;
    color: #ffffff;
    background-color: #c4c4c4;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 10px;
}
.c-course-details {
    margin-top: 10px;
    margin-bottom: 10px;
}
.c-course-details__item .icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 25px;
}
.c-course-details__item > strong {
    text-transform: uppercase;
}
/*C-CLASS*/
.c-class {
    display: block;
    margin-bottom: 70px;
}
.c-class__subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 5px;
}
.c-class__picture {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    margin-bottom: 12px;
}
.c-class__picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.c-class__button {
    height: 27px;
    background-color: #c4c4c4;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    margin-top: 10px;
}
/*C-TEACHING*/
.c-teachings {
    margin-top: 60px;
}
.c-teaching {
    display: block;
    margin-bottom: 70px;
}
.c-teaching__title {
    color: #f2f2f2;
    text-align: center;
    font-size: 22px !important;
    margin-bottom: 10px;
}
.c-teaching__figure {
    width: 230px;
    height: 230px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.c-teaching__figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
/*C-COMMENTS*/
.c-comments {
    margin-top: 25px;
}
.c-comment {
    margin: 0;
    margin-bottom: 30px;
}
.c-comment:nth-of-type(n + 4) {
    display: none;
}
.j-more-open .c-comment:nth-of-type(n + 4) {
    display: block;
}
.c-comment__text {
    position: relative;
    background-color: #f0f0f0;
    padding: 25px 15px;
    margin: 0;
}
.c-comment__text::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 50px;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    -webkit-clip-path: polygon(0 0, 0 100%, 89% 0);
    clip-path: polygon(0 0, 0 100%, 89% 0);
}
.c-comment__footer {
    text-align: right;
    font-weight: 700;
    padding-right: 120px;
    margin-top: 10px;
}
.c-other-comments,
.c-other-courses {
    display: block;
    color: #95989a;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: underline;
}
.c-other-courses {
    margin-bottom: 40px;
}
/*CTA--SMALL*/
.c-cta--small {
    position: relative;
    padding-bottom: 40px;
}
.c-cta--small .c-cta__picture::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e40043;
    mix-blend-mode: multiply;
}
.c-cta--small .c-cta__picture img {
    min-height: 255px;
}
.c-cta--small .c-cta__text {
    position: relative;
    left: 0;
    right: 0;
    bottom: auto;
    color: #fff;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.c-cta--small .c-button {
    display: block;
    height: 45px;
    color: #e4002b;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    font-weight: 700;
    padding: 0;
    margin-top: 27px;
}
/*CONTENT-PROGRAM*/
.c-content--program {
    color: #fff;
    text-align: center;
    padding: 25px 30px;
    margin-left: -20px;
    margin-right: -20px;
}
.c-content--program .c-title {
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
}
/*C-OFFER*/
.c-offers {
    margin-top: 30px;
}
.c-offer {
    display: block;
    text-align: center;
    margin-bottom: 100px;
}
.c-offer:last-child {
    margin-bottom: 0;
}
.c-offer__picture {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.c-offer__picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
/*C-SIDEBAR*/
.c-sidebar {
    display: none;
}
/*C-PROGRAM-COURSE*/
.c-program-course__item {
    font-size: 16px;
    margin-bottom: 15px;
}
.c-program-course__item-week {
    display: block;
    color: #535353;
    font-weight: 700;
    text-transform: uppercase;
}
.c-program-course__item-date {
    display: block;
    color: #838383;
    font-size: 14px;
}
.c-gallery-program {
    display: none;
}
.c-picture--program {
    margin: 20px 0;
}
/* FOOTER */
.c-site-footer {
    color: #fff;
    background-color: #000;
    font-size: 0;
    padding: 20px 0;
}
.c-site-footer__title {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.c-site-footer .c-form--inline {
    margin-bottom: 20px;
}
.c-site-footer .c-form--inline .c-form__field__input::-moz-placeholder {
    color: #000;
    text-transform: uppercase;
}
.c-site-footer .c-form--inline .c-form__field__input:-ms-input-placeholder {
    color: #000;
    text-transform: uppercase;
}
.c-site-footer
    .c-form--inline
    .c-form__field__input::-webkit-input-placeholder {
    color: #000;
    text-transform: uppercase;
}
.c-site-footer .c-form--inline .c-form__field__input {
    color: #000;
    text-align: center;
    font-weight: 600;
}
.c-social,
.c-mail {
    display: inline-block;
    vertical-align: middle;
}
.c-social__item,
.c-mail__item {
    display: inline-block;
    vertical-align: middle;
    width: 52px;
    height: 52px;
    color: #e4002b;
    background-color: #1e1e1e;
    text-align: center;
    font-size: 25px;
    line-height: 56px;
    border-radius: 50%;
}
.c-social__item {
    margin-right: 15px;
}
.c-mail__caption {
    display: none;
}
.c-site-footer__menu {
    text-align: center;
    list-style: none;
    padding: 18px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin: 20px 0;
}
.c-site-footer__menu__item {
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}
.c-site-footer__menu__item a {
    display: block;
}
.c-site-footer__contact {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
}
.c-site-footer__contact__logo {
    width: 'auto';
    margin-bottom: 5px;
}
.c-site-footer__contact__caption {
    display: block;
    font-size: 14px;
    line-height: 20px;
}
.c-site-footer__policy {
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.c-site-footer__policy__item {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    line-height: 20px;
}
.c-site-footer__policy__item:nth-of-type(n + 2)::before {
    content: '|';
    margin: 0 8px;
}
.c-agenti-container svg {
    outline: none;
}
.c-distribuzione .c-retailers__field::before,
.c-agenti-container .c-retailers__field::before {
    background-image: url('../../assets/img/search-grey.png');
}
.c-distribuzione .c-retailers .c-form__field__input,
.c-agenti-container .c-retailers .c-form__field__input {
    border-color: #95989a;
}
.c-distribuzione .l-container--retailers {
    padding: 0;
}
body.overflow-blocked {
    position: fixed;
    overflow: hidden;
}
/* hide recaptcha */
.grecaptcha-badge {
    display: none;
}
@media screen and (max-width: 1023px) {
    .c-agenti-container .l-container {
        position: relative;
    }
    .l-container--retailers {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (max-width: 767px) {
    .c-home-retailers {
        display: block !important;
        overflow: hidden;
    }
}
