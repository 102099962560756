.content {
  color: #fff;
  text-align: center;
  padding: 5em 0 5em 0;
}

.content h1 {
  text-align: left;
  font-size: 44px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.content p {
  color: #000;
}
