.content {
  color: #fff;
}

.content h1 {
  text-align: left;
  font-size: 44px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 3em 0 1em 0;
}

.content p {
  color: #000;
}
