.main-container {
    display: flex;
    height: 600px;
    flex-direction: row;
}
.italy-map {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #696969;
    flex: 1;
    overflow: hidden;
}
.main-container article footer {
    width: auto !important;
    max-width: 480px !important;
}
@media screen and (max-width: 1023px) {
    .italy-map {
        display: none;
    }
    .main-container {
        flex-direction: column;
    }
    .main-container article {
        position: relative;
        background-image: url('../../assets/img/italy.svg');
        background-color: #696969;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .main-container article::before {
        content: '';
        position: absolute;
        top: 50px;
        left: 20px;
        right: 20px;
        bottom: 75px;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .main-container article h1 {
        font-size: 22px !important;
        margin-bottom: 6px;
    }
    .main-container article footer {
    }
}
