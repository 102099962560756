.container {
    display: flex;
    align-items: center;
    margin: 2em;
    flex-direction: column;
}

.container > h2 {
    font-size: 2em;
    margin: 1em 0;
}

.inputsContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
    grid-gap: 1.2em;
    max-width: 800px;
}

.inputsContainer input,
.inputsContainer select {
    height: 30px;
    width: 200px;
}

.inputsContainer > label > span {
    display: block;
}
