.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;
    margin: auto;
    width: 80%;
}

.item-container {
    display: flex;
    align-items: center;
    margin: 1em 0;
}

.item-container img {
    width: 115px;
    height: 115px;
}

.info {
    margin-left: 1em;
}

.info h2,
.subtitle {
    text-transform: uppercase;
}

.info h2,
.description {
    font-weight: bold;
}

.info h2,
.subtitle,
.info p {
    color: #262626;
}

.subtitle {
    font-weight: 200;
}

@media screen and (max-width: 480px) {
    .container {
        flex-direction: column;
    }
}
