.products {
    margin: 2em 0;
}

.products > h4 {
    font-size: 1.3em;
    text-transform: uppercase;
    margin: 2em 0;
}

.products > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.products > table > tbody tr td {
    text-align: center;
    font-size: 1.3em;
    font-weight: 200;
    padding: 2em 0;
    border-bottom: 2px solid #212121;
}

.products > table > tbody tr td p:first-child {
    font-weight: bold;
}

.products > table > tbody > tr > td > div {
    display: inline-block;
    text-align: left;
}

.products > table > tfoot tr td {
    text-align: center;
    font-size: 1.3em;
    font-weight: 200;
    padding: 2em 0;
}

.products > table > tfoot tr:first-child td:last-child,
.products > table > tfoot tr:first-child td:nth-last-child(2) {
    background-color: #d6d6d6;
    padding: 1em 0;
    border-top: 1px solid #212121;
}

.products > table > tfoot tr:last-child td:last-child,
.products > table > tfoot tr:last-child td:nth-last-child(2) {
    background-color: #d6d6d6;
    font-weight: bold;
    padding: 1em 0;
    border-bottom: 3px solid #212121;
}

.footer-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #212121;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 2em 0;
}

.payment {
    padding: 6em 0;
}

.payment h2 {
    color: #e80444;
    font-size: 1.5em;
    text-transform: uppercase;
}

.payment-types {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-types > p {
    margin: 0 0.5em;
}

.address {
    margin-top: 4em;
}

.address-list {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
}

.address-list > li {
    margin-right: 1em;
}

.address-list input {
    margin-right: 2em;
}

.address-list p {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .header {
        display: block;
    }

    .products > table,
    .products > table > tbody,
    .products > table > tfoot {
        display: block;
    }

    .products > table > tbody tr {
        display: grid;
        padding: 1em 0;
        border-bottom: 2px solid #212121;
        grid-template-columns: 1fr 1fr;
    }
    .products > table > tbody tr td {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em 0;
        border: none !important;
    }
    .products > table > tbody tr td img {
        margin-right: 0 !important;
    }
    .products > table > tfoot tr {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .products > table > tfoot tr td {
        padding: 0;
    }
    .payment {
        padding: 3em 0 1em;
    }
    .payment-types {
        display: block;
    }
    .payment-types h2 {
        text-align: center;
        margin-bottom: 1em;
    }
}
