.row > * {
    margin: 1em 0;
}

.header {
    color: #fff;
}

.header h1 {
    text-align: left;
    font-size: 44px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 3em 0;
}

.simpleText {
    color: #606060;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
}

.content h2 {
    text-align: center;
    font-family: 'Roboto';
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2em 0;
}

.content input,
.content select {
    height: 27px;
    color: #606060;
    background-color: #ffffff;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #95989a;
    overflow: hidden;
}

.content label {
    display: flex;
    color: #606060;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    flex-direction: column;
}

.subtext {
    color: #969696;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px !important;
}

.checkboxes > label {
    align-items: center;
    color: #606060;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    flex-direction: row;
}

.checkboxes > label > input {
    margin-right: 0.5em;
}

@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
    .row {
        display: flex;
        margin: 2em 0;
    }
    .row > * {
        margin: 0;
        margin-right: 1em;
        flex: 1;
    }
    .row > *:last-child {
        margin-right: 0;
    }
    .checkboxes > label {
        width: 60%;
    }
}
