.wrapper {
  width: 80%;
  margin: auto;
}

.anagraphic-container {
  display: flex;
  flex-flow: row wrap;
}

.anagraphic-container div p {
  margin: 0;
}

.anagraphic-container div p:first-child {
  font-weight: bold;
}

.item-container {
  flex: 0 33.3%;
  margin: 0.8em 0;
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  flex: 0 20%;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
}

.checkbox-container input {
  margin-right: 1em;
}

.bottom-banner {
  margin: 2em auto;
  padding: 0.5em;
  width: 100%;
  border: 1px solid #222;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .anagraphic-container {
    display: flex;
    flex-direction: column;
  }
  .wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    width: 80%;
  }
}
