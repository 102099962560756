.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  position: relative;
  cursor: pointer;
  margin: 0.2em auto;
}

.container h2 {
  text-transform: uppercase;
  color: #262626;
  font-size: 1.2em;
}

.info-container {
  max-height: 0;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
}

.expand-icon {
  color: #262626;
  font-size: 12px;
}

.icon-container {
  width: 18px !important;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid #262626;
  position: absolute;
  right: 2em;
  cursor: pointer;
}

.opened {
  max-height: 1000em;
  transition: max-height 0.5s ease-in-out;
}

.opened-larger {
  max-height: 100em;
  transition: max-height 0.5s ease-in-out;
}

@media screen and (max-width: 480px) {
  .icon-container {
    right: 1em;
  }
}
