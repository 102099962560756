.body {
  text-align: center;
}

.body > .box {
  padding: 2em;
  color: #606060;
}

.body > .box > p {
  padding-top: 1em;
  font-weight: bold;
}

.body > .descr {
  font-size: 1.5em;
}

.body > .info-text > .text {
  margin: 2em 0;
  font-size: 1.3em;
  font-weight: 200;
}

.body > .info-text > .info {
  font-weight: bold;
}

.body > .info-text > .text > b {
  font-weight: bold;
}

.icon {
  margin: 3em 0;
}
