.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1em 0;
  max-height: 2em;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
  outline: 0;
}

.item > div {
  margin-left: 0.5em;
}

.item > div > p:first-of-type {
  margin-top: 0.3em;
  margin-bottom: 0;
}

.item div p {
  font-weight: bold;
}

.item-desc {
  font-size: 12px;
  font-weight: 200;
  color: #95989a;
  margin: 0 !important;
}

.opened {
  max-height: 1000em;
  transition: max-height 0.5s ease-in-out;
}
