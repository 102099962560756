.headerWrapper {
    display: flex;
    align-items: center;
}

.headerWrapper h1 {
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
}

.content {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
}

@media screen and (max-width: 980px) {
    .content {
        flex-direction: column;
    }
}

.link {
    text-transform: uppercase;
    text-decoration: underline;
    color: #535353;
    text-align: center;
    font-weight: 700;
}

.leftContent {
    background-color: #ebebeb;
    padding: 2em;
    margin: 2em;
    flex: 0.5;
    justify-content: center;
    align-items: center;
}

.rightContent {
    padding: 2em;
    justify-content: center;
    align-items: center;
    flex: 1.5;
}

.rightContent h1 {
    color: #e4002b;
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 1em 0;
}

.infoList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2em 4em;
    color: #888888;
    font-size: 1.2em;
}
