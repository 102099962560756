@media screen and (min-width: 480px) {
    .c-site-header__logo--product {
        display: inline-block;
        max-width: 160px;
        margin-right: 20px;
    }
    .c-service-item__picture {
        width: 45%;
        margin-right: 5%;
    }
    .c-service-item__header {
        width: 50%;
        float: right;
    }
    .c-service-detail__item {
        display: block;
        margin-top: 5px;
        margin-right: 0;
    }
}
@media screen and (max-width: 700px) {
    .c-distribution-search__header__title {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
    }
    .c-distribution-search__content {
        font-size: 14px;
        font-weight: 400;
    }
}
@media screen and (min-width: 768px) {
    .alignleft {
        margin-right: 10px;
        float: left;
        clear: left;
    }
    .alignright {
        margin-left: 10px;
        float: right;
        clear: right;
    }
    .l-menu {
        font-size: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .l-menu .c-menu-item {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-right: 2%;
    }
    .l-menu .c-menu-item:nth-of-type(2n) {
        margin-right: 0;
    }
    .c-menu-item .c-title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        line-height: 50px;
        margin-bottom: 0;
    }
    .l-3col {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 15px;
    }
    .l-3col > * {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-right: 2%;
    }
    .l-3col > *:nth-of-type(2n) {
        margin-right: 0;
    }
    .c-section-title {
        font-size: 38px;
    }
    .c-content {
        font-size: 16px;
    }
    .c-content--small {
        max-width: 80%;
        margin: 0 auto;
    }
    .c-content__text {
        font-size: 16px;
    }
    .c-gallery__figure {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        height: 350px;
        margin-bottom: 0;
    }
    .c-gallery__figure img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .c-card__figure {
        height: 250px;
    }
    /* COMPANY */
    .c-content--makeup .c-content__text {
        padding-top: 55px;
        padding-bottom: 400px;
    }
    .c-filosofy-item__content {
        font-size: 16px;
    }
    .c-content--special .c-content__text {
        position: relative;
    }
    .c-content--special .u-align-left {
        position: absolute;
        top: 0;
        left: 0;
        bottom: -30px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .c-content--special .u-align-left img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .c-content--special .c-content__text {
        padding-left: 33%;
    }
    .c-content--special .u-align-left {
        width: 30%;
    }
    .c-content--rose {
        padding-top: 30px;
        padding-bottom: 45px;
    }
    /* PARTNERSHIP_POST */
    .c-post__header {
        padding-bottom: 25px;
    }
    .c-post__header .c-section-title {
        text-align: left;
        font-size: 32px;
    }
    .c-post-related .c-title {
        font-size: 24px;
    }
    .c-post .c-content {
        font-size: 16px;
    }
    .u-align-left {
        width: 50%;
        margin-right: 30px;
        margin-bottom: 20px;
        float: left;
    }
    .u-align-right {
        width: 50%;
        margin-left: 30px;
        margin-bottom: 20px;
        float: right;
    }
    /* FOOTER */
    .c-site-footer .c-form--inline {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        margin-right: 30px;
        margin-bottom: 0;
    }
    .c-site-footer__menu {
        -webkit-column-count: 3;
        column-count: 3;
    }
    .c-site-footer__contact__logo {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        margin-bottom: 0;
    }
    .c-site-footer__contact__caption {
        display: inline-block;
        vertical-align: top;
        margin-top: 8px;
    }
}
@media screen and (min-width: 1024px) {
    body.overflow-blocked {
        position: static;
        overflow: visible;
    }
    .c-hero__text-wrapper {
        height: 100% !important;
    }
    .c-hero__before-title {
        text-align: left;
        margin: 2em 0 2em 0;
    }
    .l-page--sidebar {
        position: relative;
        padding-right: 60px;
    }
    .l-page--sidebar .c-hero,
    .l-page--sidebar .l-header {
        margin-right: -60px;
    }
    .l-container {
        width: 100%;
        max-width: 1400px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .u-mobile {
        display: none;
    }
    .u-desktop {
        display: block;
    }
    .c-title {
        font-size: 26px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
    .c-title--big {
        font-size: 36px;
        margin-bottom: 20px;
    }
    .c-title--small {
        font-size: 22px;
    }
    .c-subtitle {
        font-size: 24px;
    }
    .c-picture img {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
    .c-button {
        font-size: 16px;
        padding: 10px 20px;
    }
    .c-button--small {
        height: 35px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .c-select-container {
        height: 45px;
    }
    .c-select {
        height: 45px;
        padding-left: 10px;
    }
    .c-content {
        margin-bottom: 70px;
    }
    .c-content__text--2col {
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 45px;
        column-gap: 45px;
    }
    .c-content__header {
        margin-bottom: 25px;
    }
    /*LAYOUT */
    .l-1col .c-content {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }
    .l-1col .c-content::after {
        margin: 0 auto;
        margin-top: 80px;
    }
    .l-2col,
    .l-3col,
    .l-4col,
    .l-5col {
        font-size: 0;
    }
    .l-2col > *:not(header) {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-right: 2%;
    }
    .l-2col > *:nth-child(2n) {
        margin-right: 0;
    }
    .l-2col--3-1 {
        vertical-align: middle;
    }
    .l-2col--3-1 > :first-child {
        width: 55%;
        margin-right: 5%;
    }
    .l-2col--3-1 > :nth-child(2) {
        width: 40%;
    }
    .l-2col > .c-content {
        padding-top: 25px;
        padding-right: 40px;
        padding-bottom: 70px;
        border-right: 1px solid #95989a;
    }
    .l-2col > .c-content:nth-of-type(2n) {
        padding-left: 40px;
        padding-right: 0;
        border: none;
    }
    .l-2col--small {
        max-width: 70%;
        margin: 0 auto;
    }
    .l-3col > *:not(header) {
        display: inline-block;
        vertical-align: top;
        width: 32.333%;
        margin-right: 1.5%;
    }
    .l-3col > *:nth-of-type(2n) {
        margin-right: 1.5%;
    }
    .l-3col--small > * {
        width: 28.66% !important;
        margin-right: 7% !important;
    }
    .l-3col > *:nth-of-type(3n) {
        margin-right: 0;
    }
    .l-3col--small > *:nth-of-type(3n) {
        margin-right: 0 !important;
    }
    .c-post-related .l-3col {
        padding: 20px;
    }
    .l-4col > *:not(header) {
        display: inline-block;
        vertical-align: top;
        width: 24%;
        margin-right: 1.333%;
    }
    .l-4col--small > * {
        width: 22.75% !important;
        margin-right: 3% !important;
        margin-bottom: 3% !important;
    }
    .l-4col > *:nth-of-type(4n) {
        margin-right: 0;
    }
    .l-4col--small > *:nth-of-type(4n) {
        margin-right: 0 !important;
    }
    .l-5col > *:not(header) {
        display: inline-block;
        vertical-align: top;
        width: 19%;
        margin-right: 1.25%;
    }
    .l-5col > *:nth-of-type(5n) {
        margin-right: 0;
    }
    /* RESET  MENU*/
    .c-site-header__menu__item.j-active {
        position: relative;
        padding-top: 0;
    }
    .c-site-header__menu__item--has-dropdown-wide {
        position: static !important;
    }
    .c-site-header__menu__item.j-active > a {
        padding-top: 10px;
    }
    .c-site-header__menu__item.j-active > a::before {
        display: none;
    }
    .c-site-header__menu__item.j-active .c-dropdown-menu {
        position: absolute;
        display: none;
    }
    .c-site-header__menu__item.j-active .c-site-header__menu__item__picture {
        display: none;
    }
    /* HEADER */
    .c-site-header {
        padding-top: 30px;
        padding-bottom: 0;
    }
    .c-site-header__logo {
        max-width: 310px;
    }
    .c-site-header__actions {
        position: absolute;
        top: 50px;
        right: 30px;
        display: flex;
        align-items: center;
    }
    .c-site-header__actions__item {
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        margin-right: 25px;
    }
    .c-site-header__actions__item:last-child {
        margin-right: 0;
    }
    .c-site-header__actions__item:hover {
        color: #e4002b;
    }
    .c-site-header__actions__item__cart {
        top: -8px;
    }
    .c-site-header__toggle {
        display: none !important;
    }
    .c-site-header__close {
        display: none !important;
    }
    .c-site-header__nav {
        position: static;
        display: block !important;
        width: 100%;
        height: auto;
        padding: 0 !important;
        margin-top: 20px;
        overflow: visible;
    }
    .c-site-header__menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }
    .c-site-header__menu__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
    }
    .c-site-header__menu__item > a {
        padding: 10px 10px 30px;
    }
    .c-site-header__menu__item:first-of-type > a {
        padding-left: 0;
    }
    .c-site-header .c-site-header__menu__item:last-of-type > a {
        padding-right: 0;
    }
    .c-site-header__menu__item:hover > a {
        color: #e4002b;
    }
    /* HEADER PRODUCTS */
    .c-site-header--products {
        font-size: 15px !important;
    }
    .c-site-header--products .c-site-header__logo {
        padding-bottom: 30px;
    }
    .c-site-header__logo--product {
        max-width: 220px;
    }
    .c-site-header--products .c-site-header__actions {
        position: relative;
        top: auto;
        right: auto;
        display: inline;
    }
    .c-site-header--products .c-site-header__actions__item {
        line-height: 30px;
        padding: 10px 5px 25px;
        margin-right: 0;
    }
    .c-site-header--products .c-site-header__nav {
        display: inline-block !important;
        width: auto;
        max-width: 890px;
        text-align: right;
        margin-top: 0;
        /* float: right; */
    }
    .c-site-header--products .c-site-header__menu {
        display: inline;
    }
    .c-site-header--products .c-site-header__menu__item > a {
        line-height: 30px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 25px;
    }
    /* MENU HEADER */
    .c-dropdown-menu {
        position: absolute;
        left: 50%;
        bottom: 0;
        display: none;
        background-color: #fff;
        font-size: 16px;
        line-height: 24px;
        padding: 30px 0;
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        -webkit-transform: translateX(-50%) translateY(100%);
        transform: translateX(-50%) translateY(100%);
        z-index: 999999;
        z-index: 9;
    }
    .c-site-header__menu__item--has-dropdown-wide .c-dropdown-menu {
        left: 0;
        right: 0;
        bottom: auto;
        padding-top: 55px;
        -webkit-transform: none;
        transform: none;
        z-index: 999999;
    }
    .c-dropdown-menu::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border-color: transparent transparent #ffffff transparent;
        border-width: 0 50px 25px 50px;
        -webkit-transform: translateX(-50%) translateY(-100%);
        transform: translateX(-50%) translateY(-100%);
        border-style: solid;
    }
    .c-site-header__menu__item--has-dropdown-wide .c-dropdown-menu::before {
        display: none;
    }
    /* body:not(.j-search-open):not(.j-login-open)
    .c-site-header__menu__item:hover
    .c-dropdown-menu {
     display: block !important;
  } */
    body:not(.j-search-open):not(.j-login-open)
        .c-site-header__menu__item--has-dropdown-wide:hover
        > a::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0;
        height: 0;
        border-color: transparent transparent #ffffff transparent;
        border-width: 0 50px 25px 50px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border-style: solid;
    }
    .c-site-header__menu__item--has-dropdown-wide
        .c-dropdown-menu
        .l-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        max-height: 450px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-line-pack: center;
        align-content: center;
    }
    .c-menu-list {
        display: inline-flex;
        flex-direction: column;
        order: 1;
    }
    .c-menu-list:nth-last-child(2) {
        order: 2;
    }
    .c-site-header__menu__item--has-dropdown-wide .c-menu-list {
        margin-bottom: 25px;
    }
    .c-menu-list__title {
        color: #606060;
        font-weight: bold;
        margin-bottom: 0;
    }
    .c-site-header__menu__item--has-dropdown-wide .c-menu-list__title {
        color: #484848;
        font-size: 18px;
        margin-bottom: 5px;
    }
    .c-menu-list__title > a:hover {
        color: #e4002b;
    }
    .c-menu-list__item {
        display: block;
    }
    .c-menu-list__item > a:hover {
        color: #fff;
        background-color: #000;
    }
    /* FORM */
    .c-form a,
    .c-form p,
    .c-form span {
        font-size: 18px;
    }
    .c-form__field {
        margin-bottom: 25px;
    }
    .c-form__field__label {
        font-size: 18px;
    }
    .c-form__field__input {
        height: 45px;
        padding: 10px;
    }
    .c-form__field--checkbox input {
        width: 25px;
        height: 25px;
    }
    .c-form__field--checkbox span {
        width: 25px;
        height: 25px;
    }
    .c-form__field--checkbox .c-form__field__label {
        line-height: 25px;
        padding-left: 31px;
    }
    .c-form__field--checkbox input[type='checkbox']:checked + span::before {
        top: 5px;
        left: 5px;
        width: 11px;
        height: 6px;
        border-color: #fff;
        border-width: 3px;
    }
    .c-form .c-form-link {
        font-size: 18px;
        font-weight: normal;
    }
    .c-form--search,
    .c-form--login {
        top: 150px;
    }
    .c-site-header--products .c-form--search,
    .c-form--login {
        top: 95px;
    }
    /* FORM INLINE */
    .c-form--inline .c-form__field__input {
        width: 75%;
    }
    .c-form--inline .c-button {
        width: 25%;
    }
    /* LOGIN */
    .c-form--login {
        position: absolute;
        left: 65%;
        bottom: auto;
        color: #fff;
        background-color: #000;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .c-form--login .c-form-close {
        position: absolute;
        top: 0;
        right: 30px;
    }
    .c-form--login .c-form__header {
        display: none;
    }
    .c-form--login .c-form__field__label {
        display: inline-block;
        vertical-align: middle;
        width: 21%;
        text-align: right;
        margin-right: 4%;
    }
    .c-form--login .c-form__field__input {
        display: inline-block;
        vertical-align: middle;
        width: 75%;
    }
    .c-form--login .c-form__field--checkbox {
        margin-left: 25%;
        margin-bottom: 0;
    }
    .c-form--login .c-form__field--checkbox .c-form__field__label {
        width: auto;
        margin-right: 0;
    }
    .c-form--login .c-form__footer {
        padding-top: 30px;
        padding-bottom: 0;
        border-bottom: none;
    }
    .c-form--login .c-form__footer .c-button {
        left: auto;
        right: 0;
        bottom: 100%;
        height: 30px;
        color: #fff;
        background-color: #e4002b;
        padding: 2.5px 10px;
        -webkit-transform: none;
        transform: none;
    }
    /* SEARCH */
    .c-form--search fieldset {
        margin-top: 100px;
    }
    .c-form--search .c-form__title {
        display: none;
    }
    .c-form--search .c-form__field__input {
        background-color: transparent;
        padding-left: 45px;
        border: 2px solid #fff;
    }
    .c-form--search .c-form__field .icon {
        position: absolute;
        top: 7px;
        left: 7px;
        display: block;
        color: #fff;
        font-size: 32px;
    }
    .c-form--search .c-button {
        display: inline-block;
        width: auto;
        height: auto;
        color: #e4002b;
        background-color: #fff;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 20px;
        border-radius: 0;
    }
    .c-form--search .c-button span:not(.icon) {
        display: block;
    }
    .c-form--search .c-button .icon {
        display: none;
    }
    /* HERO */
    .c-hero {
        padding-top: 0;
        padding-bottom: 0;
    }
    .c-hero:not(.c-hero--slider) {
        position: relative;
        height: 40vh;
        min-height: 400px;
    }
    .c-hero__picture {
        position: absolute;
        top: 0;
        left: 40%;
        right: 0;
        bottom: 0;
        text-align: right;
        margin: 0;
    }
    .c-hero__picture::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: black;
        background: -webkit-gradient(
            left top,
            right top,
            color-stop(0%, black),
            color-stop(19%, rgba(0, 0, 0, 0.34)),
            color-stop(29%, rgba(0, 0, 0, 0))
        );
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(black),
            color-stop(19%, rgba(0, 0, 0, 0.34)),
            color-stop(29%, rgba(0, 0, 0, 0))
        );
        background: linear-gradient(
            to right,
            black 0%,
            rgba(0, 0, 0, 0.34) 19%,
            rgba(0, 0, 0, 0) 29%
        );
        z-index: 1;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1);
    }
    .c-hero__picture img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
    .c-hero__title,
    .c-hero--academy-small .c-hero__title {
        position: absolute;
        top: 50%;
        max-width: 35%;
        max-width: calc(40% - 50px);
        text-align: left;
        font-size: 44px;
        margin-top: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .c-hero--academy-small .c-hero__title {
        bottom: auto;
        max-width: 100% !important;
    }
    .c-hero__title span {
        display: none;
    }
    .c-hero__subtititle {
        position: absolute;
        top: 60%;
        max-width: 35%;
        max-width: calc(40% - 50px);
        text-align: left;
        font-size: 22px;
        font-weight: 700;
        margin-top: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 999;
    }
    .c-hero--fullwidth .c-hero__picture {
        position: relative;
        left: auto;
        width: 100%;
        margin: 0;
    }
    .c-hero--fullwidth .c-hero__picture img {
        width: 100%;
    }
    .c-hero--fullwidth .c-hero__picture::before {
        content: '';
        display: none;
    }
    .c-hero--fullwidth .c-hero__title {
        top: auto;
        left: auto;
        bottom: 50px;
        max-width: 600px;
        -webkit-transform: none;
        transform: none;
    }
    /*HERO ACADEMY*/
    .c-hero--academy .c-title {
        font-size: 100px;
    }
    .c-hero--academy .c-subtitle {
        font-size: 42px;
        margin-top: 50px;
    }
    .c-hero--city {
        height: 30vh !important;
        min-height: 285px !important;
        padding: 33px 0;
    }
    .c-hero__detail {
        margin: 0 auto;
        margin-top: 40px;
    }
    .c-hero__detail img {
        position: absolute;
        top: 50%;
        right: 0;
        width: 150px;
        height: 150px;
        margin: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .c-hero__detail-text {
        font-size: 18px;
    }
    .c-hero--academy-small::after {
        display: none;
    }
    .c-category-nav {
        display: block;
        background-color: #000;
    }
    .c-category-nav__menu {
        text-align: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .c-category-nav__menu__item {
        display: inline-block;
        vertical-align: top;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 50px;
    }
    .c-category-nav__menu__item a {
        display: block;
        padding: 35px 0;
    }
    .c-slide {
        height: 60vh;
        min-height: 600px;
    }
    .c-slide__cta {
        top: 45px;
        left: -90px;
        right: auto;
        background-color: #e4002b;
        padding: 15px 100px;
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
    }
    .c-slide__cta__title {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .c-slide__cta__date {
        display: none;
    }
    .c-slide__article.right {
        left: 60%;
        right: 45px;
        padding-top: 40px;
    }
    .c-slide__article.left {
        left: 45px;
        right: 60%;
        padding-top: 40px;
    }
    .c-slide__article.center {
        top: 25%;
        left: 0;
        right: 0;
    }
    .c-slide__article.video {
        top: 15%;
        left: 60%;
        right: 0;
        bottom: 15%;
    }
    .c-slide__article__picture {
        max-width: 360px;
        margin-bottom: 30px;
    }
    .c-slide__article__picture.center {
        max-width: none;
    }
    .c-slide__article__picture.center > img {
        max-height: 180px;
    }
    .c-slide__article__text .c-title {
        font-size: 34px;
    }
    .c-slide .c-button {
        display: inline-block;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 10px;
    }
    .c-slide .c-button.right {
        left: 30%;
        bottom: 80px;
    }
    .c-slide .c-button.left {
        left: calc(75% - 60px);
        bottom: 80px;
    }
    .c-slide .c-button.center {
        left: 50%;
        bottom: 15px;
    }
    /*SLIDER*/
    .c-slider {
        padding-right: 0;
        padding-bottom: 0;
        margin-bottom: 60px;
    }
    .c-slider .slick-arrow {
        top: 50%;
        bottom: auto;
        font-size: 65px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .c-slider .slick-truccheria-prev {
        left: 30px;
        -webkit-transform: translateY(-50%) rotateY(180deg);
        transform: translateY(-50%) rotateY(180deg);
    }
    .c-slider .slick-truccheria-next {
        right: 30px;
    }
    /* BREADCRUMBS */
    .c-breadcrumbs {
        display: block;
        color: #2b2b2b;
        font-size: 0;
    }
    .c-breadcrumbs__item {
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
        text-transform: uppercase;
        padding: 20px 0;
    }
    .c-breadcrumbs__item::after {
        content: '-';
        margin: 0 5px;
    }
    .c-breadcrumbs__item:first-child {
        text-transform: none;
    }
    .c-breadcrumbs__item.current {
        font-weight: 600;
    }
    .c-breadcrumbs__item.current::after {
        display: none;
    }
    a.c-breadcrumbs__item:hover {
        color: #e4002b;
    }
    /* MENU */
    .l-menu .c-menu-item:nth-of-type(n) {
        width: 24.5%;
        margin-right: 0.666%;
    }
    .l-menu .c-menu-item:nth-of-type(4n) {
        margin-right: 0;
    }
    .c-menu-item .c-picture {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
    .c-menu-item:hover .c-picture {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }
    .c-menu-item:hover .c-title {
        bottom: -80px;
        opacity: 0;
    }
    /* CTA */
    .c-cta__picture img.desktop {
        display: block;
    }
    .c-cta__picture img.mobile {
        display: none;
    }
    .c-cta__text {
        left: 50%;
        right: 5%;
        bottom: 60px;
    }
    .c-cta .c-title {
        font-size: 40px;
        margin-bottom: 20px;
    }
    .c-cta .c-subtitle {
        margin-bottom: 30px;
    }
    .c-cta:hover .c-button {
        color: #fff;
        background-color: #e4002b;
        border-color: #e4002b;
    }
    /* BLOG */
    .l-blog {
        font-size: 0;
    }
    .l-blog .c-blog-item {
        display: inline-block;
        vertical-align: top;
        width: 33.333%;
        height: 320px;
    }
    .l-blog .c-blog-item:nth-of-type(5n-4),
    .l-blog .c-blog-item:nth-of-type(5n-3) {
        width: 50%;
        height: 480px;
    }
    .c-blog-item__overlay {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-color: #e4002b;
        background-color: rgba(228, 0, 68, 0.6);
        overflow: hidden;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
    .c-blog-item__overlay__title {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 30px;
        line-height: 44px;
        text-transform: uppercase;
        border-bottom: 5px solid #fff;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }
    .c-blog-item:hover .c-blog-item__overlay {
        top: 0;
    }
    .c-blog-item:hover .c-title {
        opacity: 0;
    }
    /* PRODOTTI */
    .l-products {
        text-align: center;
    }
    .c-product-item {
        margin-top: 60px;
    }
    .c-product-item--boutique .c-product-item__picture::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e40043;
        opacity: 0;
        transition: 0.25s ease;
        z-index: 1;
        mix-blend-mode: multiply;
    }
    .c-product-item--boutique .c-product-item__picture::after {
        content: url('../../assets/img/logo-small.png');
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: 0.3s ease;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    .c-product-item--boutique > a:hover .c-product-item__picture::before,
    .c-product-item--boutique > a:hover .c-product-item__picture::after {
        opacity: 1;
    }
    .c-product-item .c-title {
        font-size: 22px;
    }
    .c-product-item__footer {
        display: none;
    }
    /* PRODOTTO */
    .c-product {
        padding-top: 60px;
        margin-bottom: 70px;
        overflow: visible;
    }
    .c-product::after {
        margin: 0 auto;
        margin-top: 80px;
    }
    .c-header-product {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
        -webkit-box-shadow: 0 0 25px 1px #b7b7b7;
        box-shadow: 0 0 25px 1px #b7b7b7;
    }
    .c-header-product.j-sticky {
        display: block;
    }
    .c-header-product .c-title {
        display: inline-block;
        vertical-align: middle;
        color: #606060;
        margin-right: 60px;
    }
    .c-header-product .c-color {
        width: 60px;
        height: 60px;
        margin-right: 50px;
    }
    .c-product-slider {
        margin-left: 0;
        margin-right: -30px;
    }
    .c-product__header {
        width: 45%;
        font-size: 18px;
        margin-bottom: 60px;
        float: right;
    }
    .c-product__header .c-new {
        top: 0;
        right: 0;
        width: 100px;
        height: 100px;
        text-align: center;
        font-size: 20px;
        line-height: 100px;
        padding: 0;
        border-radius: 50%;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    .c-product__picture {
        width: 45%;
        margin-right: 5%;
        float: left;
    }
    .c-product__description {
        width: 45%;
        float: right;
    }
    .c-product__description .c-select-container {
        margin-bottom: 20px;
    }
    .c-color-picker {
        margin-bottom: 40px;
    }
    .c-color {
        width: 40px;
        height: 40px;
    }
    .c-color.selected {
        width: 36px;
        height: 36px;
        outline: none;
    }
    .c-price {
        text-align: right;
        font-size: 18px;
    }
    .c-price span {
        font-size: 26px;
    }
    .c-product__footer {
        text-align: left;
        font-size: 18px;
    }
    /* SERVICE */
    .c-service-item__picture {
        width: 33%;
        margin-right: 7%;
        margin-bottom: 0;
    }
    .c-service-item .c-content {
        position: relative;
        width: 60%;
        margin-bottom: 0;
        float: right;
    }
    .c-service-item .c-title {
        font-size: 28px;
    }
    .c-service-detail h3,
    .c-service-detail h4 {
        font-size: 16px;
    }
    /* TODO problems in react 
  .c-service-detail {
      position: absolute;
      left: 0;
      bottom: 0; 
  }*/
    .c-service-detail__item {
        display: inline-block;
        line-height: 21px;
        margin-right: 20px;
    }
    .c-service-item .c-content__text {
        margin-bottom: 30px;
    }
    .c-service-item__footer {
        text-align: right;
        padding-right: 20px;
    }
    .c-service-item__footer .c-button {
        color: #606060;
        background: none;
        font-weight: 400;
        padding: 0;
        border: 0;
    }
    .c-service-item__footer .c-button .icon {
        margin-right: 10px;
    }
    /* COMPANY */
    .l-4col > .c-filosofy-item {
        width: 22%;
        margin-right: 4%;
    }
    .l-4col > .c-filosofy-item:nth-of-type(4n) {
        margin-right: 0;
    }
    /* PARTNERSHIP */
    .c-logo-grid__logo {
        margin-left: 30px;
        margin-right: 30px;
    }
    /* PARTNERSHIP_POST */
    .c-post__footer {
        padding-top: 40px;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    /* DISTRIBUZIONE */
    .c-distribution-search {
        width: 70%;
    }
    .c-distribution-form {
        position: relative;
    }
    .c-distribution-search .c-button {
        position: absolute;
        top: 0;
        left: initial;
        right: 0;
        margin-top: initial;
        -webkit-transform: none;
        transform: none;
    }
    .c-distribution-search .c-form__field__input {
        padding-right: 50px;
    }
    .c-distribution-search__content {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 35px;
    }
    .c-distribution-search--special {
        width: 100%;
    }
    .c-distribution-search--special .c-distribution-search__content {
        text-align: left;
    }
    .c-agenti-distributori__header {
        text-align: left;
        padding-left: 30px;
    }
    .c-agenti-distributori__list__item__link-maps {
        display: none;
    }
    .c-form__field--inline {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-right: 2%;
    }
    .c-form__field--inline:nth-of-type(3) {
        margin-right: 0;
    }
    .c-distribution-map {
        position: relative;
    }
    .c-distribution-map .c-map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-color: #f1f3f4;
    }
    .c-agenti-distributori {
        position: relative;
        left: 63%;
        width: 37%;
        min-height: 600px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
    }
    .c-agenti-distributori .l-container {
        padding-left: 25px;
    }
    .c-box-container {
        justify-content: center;
        width: 95%;
        margin: auto;
    }
    .c-box-item {
        flex: 1 1 0%;
    }
    .c-box-item p {
        font-size: 17px;
    }
    /* PAGE */
    .c-page-title {
        font-size: 40px;
    }
    .c-page__picture {
        margin-bottom: 40px;
    }
    .c-page-block .c-contact {
        margin-bottom: 60px;
    }
    .c-page-block .c-map {
        margin-bottom: 60px;
    }
    .c-contact-container {
        margin-bottom: 60px;
    }
    .c-contact-container .c-contact {
        width: 380px;
        margin-left: auto;
        margin-right: 20px;
        margin-bottom: 30px;
    }
    .c-contact-container .c-map {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 55%;
        margin: 0;
    }
    .c-contact-container .j-map {
        height: 100%;
    }
    .c-contact-container .c-map iframe {
        height: 100%;
    }
    .c-contact__header {
        margin-bottom: 40px;
    }
    /* TODO to verify
  .c-page-block--picture .l-2col .c-map {
     width: calc(50% + ((100vw - 1280px) / 2));
    margin-left: calc(0px - ((100vw - 1280px) / 2)); 
  } */
    .c-page-block:not(.c-page-block--picture) .c-contact-list ul {
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 150px;
        column-gap: 200px;
    }
    .c-contact-list__item {
        font-size: 20px;
    }
    .c-retailers__field::before {
        width: 30px;
        height: 30px;
    }
    .c-retailers .c-form--inline {
        display: block;
        max-width: 800px;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
        margin: 0 auto;
    }
    .c-retailers .c-form--inline .c-form__field__input {
        font-size: 18px;
    }
    .c-related {
        padding-bottom: 60px;
    }
    .c-related__header {
        padding-top: 50px;
    }
    .c-related__header .c-title {
        font-size: 34px;
    }
    .c-related .c-product-item__footer {
        display: block;
    }
    /*ACADEMY*/
    .l-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0;
    }
    .c-content-block--grey {
        background-color: #f2f2f2;
    }
    .c-content-block--rose-dark {
        background-color: #e40043;
    }
    .c-content-block--rose-dark .c-excerpt {
        width: 49%;
        margin-right: 2%;
    }
    .c-content-block--rose-dark .c-excerpt:nth-child(2n) {
        margin-right: 0;
    }
    .c-content-block__footer {
        margin-top: 70px;
    }
    .c-excerpt__figure {
        height: 30vh;
    }
    .c-excerpt__text {
        font-size: 16px;
    }
    .c-excerpt__footer .c-button {
        font-size: 14px;
    }
    .c-academy-search {
        position: relative;
    }
    .c-academy-search__header {
        height: 530px;
    }
    .c-academy-search__title {
        font-size: 36px;
    }
    .c-academy-search__subtitle {
        font-size: 24px;
    }
    .c-academy-search__header-text {
        top: 50%;
        left: 50%;
        right: auto;
        width: 70%;
        padding: 40px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .c-academy-search__header-text::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    }
    .c-form--filter-academy {
        position: absolute;
        top: 53%;
        left: 50%;
        width: 60%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .c-form--filter-academy {
        font-size: 0;
        border: 1px solid #95989a;
    }
    .c-form--filter-academy .c-form__field {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
    }
    .c-form--filter-academy .c-select-container {
        border: none;
    }
    .c-form--filter-academy .c-select {
        font-size: 18px;
    }
    .c-downloads {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-around;
        -ms-flex-pack: distribute;
    }
    .c-downloads .c-button {
        border-color: transparent;
    }
    .c-content--picture {
        position: relative;
        padding-left: 25%;
    }
    .c-content--picture .c-content__figure {
        position: absolute;
        top: 0;
        left: 30px;
        bottom: 0;
        width: 20%;
        margin-bottom: 0;
    }
    .c-list {
        font-size: 16px;
    }
    .c-teachings {
        margin-top: 50px;
    }
    .c-comment__text {
        padding: 60px 30px;
    }
    .c-comment__text::after {
        right: 75px;
        width: 35px;
        height: 35px;
    }
    .c-comment__footer {
        font-size: 18px;
        padding-right: 130px;
    }
    .c-other-comments {
        color: #95989a;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: underline;
    }
    .c-other-courses {
        display: none;
    }
    .c-classes {
        font-size: 0;
    }
    .c-classes .c-class {
        display: inline-block;
        vertical-align: top;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 48px;
    }
    .c-class--big .c-class__picture {
        width: auto;
        height: 30vh;
    }
    .c-classes .c-class__picture {
        width: 100%;
        height: 30vw;
    }
    .c-classes .c-class:nth-child(7n + 3),
    .c-classes .c-class:nth-child(7n + 6) {
        margin-right: 0;
    }
    .c-classes .c-class:nth-child(7n + 7) {
        width: 100%;
        margin-right: 0;
    }
    .c-class__button {
        display: none;
    }
    /*CTA--SMALL*/
    .c-cta--small {
        height: 255px;
        padding-bottom: 0;
    }
    .c-cta--small .c-cta__picture {
        height: 255px;
    }
    .c-cta--small .c-cta__picture img {
        width: 50%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .c-cta--small .c-cta__text {
        position: absolute;
        left: 50%;
        right: 5%;
        bottom: 60px;
    }
    .c-cta--small .c-button {
        max-width: 60%;
        margin: 0 auto;
        margin-top: 30px;
    }
    /*content-program*/
    .c-content--program {
        padding: 35px 65px !important;
        margin-top: 0 !important;
        margin-left: 0;
        margin-right: 0;
    }
    .c-content--program .c-title {
        font-size: 22px;
    }
    .c-course-details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 35px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }
    .c-course-details__item {
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    .c-course-details__item--small {
        align-items: flex-start;
    }
    .c-course-details__item svg {
        margin-right: 10px;
    }
    .c-courses {
        max-width: 50%;
        margin: 0 auto;
    }
    .c-course__button:hover {
        color: #fff;
        background-color: #e40043;
    }
    /*BUTTON--WHERE*/
    .c-button--where {
        display: block;
        color: #535353;
        background-color: #f2f2f2 !important;
        text-align: center;
        font-size: 20px !important;
        padding: 20px !important;
        border: 1px solid #95989a;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin-left: 0;
        margin-bottom: 20px;
    }
    .c-content-block--grey .c-button--where {
        background-color: #ffffff !important;
    }
    .c-button--where strong {
        display: none;
    }
    .c-button--where.u-mobile {
        display: inline-block;
        text-align: center;
        padding: 10px 20px !important;
        border: 1px solid #95989a;
        margin-left: 0;
    }
    .c-button--where:hover {
        color: #fff !important;
        background-color: #e40043 !important;
    }
    /*C-OFFER*/
    .c-offers .c-offer {
        width: auto;
        margin: 0 5%;
    }
    /*C-SIDEBAR*/
    .c-sidebar {
        position: absolute;
        top: 400px;
        right: 0;
        bottom: 0;
        display: block;
        width: 60px;
        background-color: rgba(242, 242, 242, 0.85);
        padding: 45px 29px;
        border: 1px solid #95989a;
        overflow: hidden;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
    .j-sidebar-open .c-sidebar {
        width: 585px;
        padding: 45px;
    }
    .c-sidebar__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }
    .c-sidebar__toggle {
        position: absolute;
        top: 40px;
        left: 5px;
        display: inline-block;
        margin-right: 20px;
    }
    .j-sidebar-open .c-sidebar__toggle {
        position: relative;
        top: auto;
        left: auto;
    }
    .c-sidebar__toggle__item {
        display: block;
        width: 47px;
        border-top: 6px solid #e40043;
        margin: 6px 0 6px auto;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
    .j-sidebar-open .c-sidebar__toggle__item {
        opacity: 0;
    }
    .j-sidebar-open .c-sidebar__toggle__item:first-of-type {
        opacity: 1;
        -webkit-transform: rotate(-45deg) translate(-6px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
    }
    .j-sidebar-open .c-sidebar__toggle__item:last-of-type {
        opacity: 1;
        -webkit-transform: rotate(45deg) translate(-11px, -11px);
        transform: rotate(45deg) translate(-11px, -11px);
    }
    .c-sidebar__title {
        font-size: 32px;
        margin-bottom: 0;
        opacity: 0;
    }
    .j-sidebar-open .c-sidebar__title {
        opacity: 1;
    }
    .c-sidebar__content {
        width: 493px;
        margin-bottom: 40px;
        opacity: 0;
    }
    .j-sidebar-open .c-sidebar__content {
        opacity: 1;
    }
    .c-sidebar__content-title {
        margin-bottom: 30px;
    }
    .c-sidebar .c-course {
        min-height: 120px;
    }
    .c-sidebar .c-course__figure {
        height: 120px;
    }
    .c-sidebar .c-course__title {
        color: #535353;
        font-size: 20px;
        margin-bottom: 3px;
    }
    .c-sidebar .c-course__lead {
        font-size: 18px;
    }
    /*GALLERY-PROGRAM*/
    .c-gallery-program {
        display: block;
    }
    .c-gallery-program__item {
        margin-bottom: 10px;
    }
    /*C-TABLE*/
    .c-table--big {
        font-size: 15px;
    }
    .c-table--big thead th {
        font-size: 14px;
    }
    /* FOOTER */
    .c-site-footer {
        padding: 50px 0;
    }
    .c-site-footer__title {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .c-site-footer .c-form--inline {
        width: 40%;
        margin-right: 10%;
    }
    /* .c-social {
     width: 28%; 
  } */
    .c-social__item,
    .c-mail__item {
        width: 70px;
        height: 70px;
        font-size: 40px;
        line-height: 76px;
    }
    .c-mail {
        width: 22%;
        text-align: right;
    }
    .c-mail__caption {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 15px;
    }
    .c-site-footer__menu {
        padding: 28px 0;
        margin: 30px 0;
    }
    .c-site-footer__contact {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 0;
        border: none;
        margin-bottom: 0;
    }
    .c-site-footer__policy {
        margin-top: 8px;
        float: right;
    }
}
@media screen and (min-width: 1280px) {
    .c-hero__before-title {
        margin-left: 40px;
    }
    .l-container {
        padding-left: 60px;
        padding-right: 60px;
    }
    .l-container--large {
        max-width: 1500px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .c-site-header {
        font-size: 18px;
    }
    .c-site-header__actions {
        right: 20px;
    }
    .c-site-header__menu__item > a {
        padding-left: 20px;
        padding-right: 20px;
    }
    .c-hero__title,
    .c-hero--academy-small .c-hero__title {
        max-width: 35%;
        max-width: calc(40% - 120px);
        margin-left: 40px;
    }
    .c-hero__subtititle {
        max-width: 35%;
        max-width: calc(40% - 120px);
        margin-left: 40px;
    }
    .c-menu-list {
        margin-right: 50px;
    }
    .c-site-header__menu__item:not(.c-site-header__menu__item--has-dropdown-wide)
        .c-menu-list:last-of-type {
        margin-right: 0;
    }
    .c-menu-list__title > a {
        display: block;
        padding-left: 35px;
        padding-right: 35px;
    }
    .c-menu-list__item > a {
        display: block;
        padding-left: 35px;
        padding-right: 35px;
    }
    .c-social__item {
        margin-right: 20px;
    }
    .c-mail__caption {
        margin-right: 20px;
    }
    .c-service-detail__item {
        font-size: 18px;
        margin-right: 50px;
    }
    .c-service-item__footer {
        padding-right: 50px;
    }
    .c-service-item__footer .c-button {
        font-size: 18px;
    }
    .c-box-item {
        padding-left: 25px;
        padding-right: 25px;
    }
    .c-box-item p {
        font-size: 20px;
    }
}
@media screen and (min-width: 1400px) {
    .c-cta__text {
        right: 15%;
    }
    .c-hero__title {
        left: calc((100% - 1280px) / 2);
        max-width: calc(40% - ((100% - 1280px) / 2) - 60px);
    }
    .c-hero__subtititle {
        left: calc((100% - 1280px) / 2);
        max-width: calc(40% - ((100% - 1280px) / 2) - 60px);
    }
    .c-product .l-container {
        padding-left: 200px;
    }
    .c-classes .c-class__picture {
        height: 405px;
    }
    .c-box-item {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (min-width: 1500px) {
    .c-site-header__actions {
        right: calc((100% - 1460px) / 2);
    }
}
