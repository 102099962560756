.label {
    color: #606060;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
}

.document {
    width: 100%;
    padding: 1.2em;
    border: 1px solid #95989a;
}

.left {
    margin-bottom: 20px;
}

.box {
    background-color: #f2f2f2;
    padding: 2em;
}

.right ul {
    margin: 1em 1em;
    list-style-type: disc;
}

.subtext {
    color: #969696;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
}

textarea {
    width: 100%;
    color: #606060;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    padding: 0 0.5em;
    margin: 1em 0;
}

@media screen and (min-width: 768px) {
    .document {
        display: flex;
        justify-content: space-between;
        padding: 2em;
    }

    .left {
        width: 40%;
    }

    .right {
        width: 40%;
    }
}
