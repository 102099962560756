.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    background-image: url('../../assets/img/italy.svg');
    background-color: #696969;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.mufeBox {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 843px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 30px 85px;
    margin: 47px;
    flex-direction: column;
}
@media screen and (max-width: 700px) {
    .mufeBox {
        padding: 30px 20px;
        margin: 0 20px;
    }
}