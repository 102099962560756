.orders-table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
}

.orders-table thead {
  background-color: #f2f2f2;
}

.orders-table thead tr {
  margin: 0 5em;
}

.orders-table thead tr td {
  padding: 0.3em 0;
  text-transform: uppercase;
  font-weight: 200;
}

.orders-table tbody tr {
  border-bottom: 1px solid #262626;
}

.orders-table tbody tr td {
  padding: 1em 0;
}

.orders-table tbody tr td:first-child {
  font-weight: bold;
}

.orders-table tbody tr td:last-child {
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 440px) {
  .orders-table {
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .orders-table {
    font-size: 11px;
  }
}

@media screen and (max-width: 340px) {
  .orders-table {
    font-size: 10px;
  }
}
