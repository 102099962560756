.typologyTabMobile {
    display: none;
    text-align: center;
    padding-bottom: 30px;
}

.typologyTabMobile img {
    margin-top: 40px;
}

.typologyTabMobile p {
    color: #535353;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    margin: 0 26px;
}

.item p {
    color: #000000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

@media screen and (max-width: 940px) {
    .typologyTabMobile {
        display: block;
    }
}
