.container {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container h1 {
    text-align: center;
    text-transform: uppercase;
    padding-top: 3em;
    font-size: 28px;
    font-weight: 700;
    font-family: 'Roboto';
}

.content img {
    margin: 4em;
}

.content p {
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
}

.content a {
    font-family: 'Roboto';
    text-decoration: underline;
    margin: 4em;
    font-size: 16px;
    font-weight: 400;
}
