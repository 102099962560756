.title {
    text-align: left;
    font-size: 44px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 3em 0 1em 0;
    color: #e4002b;
    margin-top: 1em;
}

.paragraph {
    font-size: 1.4em;
    margin: 2em 0 !important;
}
