.content {
    color: #fff;
}

.content h1 {
    text-align: left;
    font-size: 44px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 3em 0;
}

.boxes {
    display: flex;
    margin: 2em 0;
}

.boxes > a {
    color: #000;
    font-weight: bold;
    background-color: #fff;
    flex: 1;
    padding: 1.4em;
    margin: 0 1em;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.boxes > a:first-child {
    margin-left: 0;
}

.text {
    display: flex;
    padding-bottom: 8em;
}

.text > p {
    flex: 1;
    margin: 0 4em;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
}

.text > p:first-child {
    margin: 0;
}

@media screen and (max-width: 830px) {
    .boxes {
        flex-direction: column;
    }

    .boxes > a {
        margin: 1em 0;
    }
}
