.header-title {
    color: #e4002b;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    padding-top: 1em;
}

@media screen and (min-width: 768px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-title {
        padding-bottom: 1em;
    }
}
