.form {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: auto;
  flex-flow: row wrap;
}

.form > input[type='text'] {
  width: calc(50% - 1em);
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  margin: 0.5em;
}

.form > input[type='text']::placeholder,
.form > textarea::placeholder {
  text-align: left;
}

.form > div {
  width: calc(50% - 1em);
  margin: 0.5em 0.5em;
}

.form > div > select {
  height: 15px;
  color: #95989a;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.form > textarea {
  width: calc(100% - 0.8em);
  height: 145px;
  margin: auto;
}

.form > input[type="submit"] {
    color: #fff;
    background-color: #8b8b8b;
    border-color: #8b8b8b;
}

.form > input[type="submit"]:hover{
    background-color: #000;
    border-color: #000;
}

@media screen and (max-width: 768px) {
  .form {
    align-items: center;
    width: 100%;
    flex-flow: column;
  }
  .form > input[type='text'] {
    width: calc(100% - 1em);
  }
  .form > div {
    width: calc(100% - 1em);
    margin: 0.5em;
  }
}
