.order-number {
    color: #e4002b;
    font-size: 2em;
    font-weight: bold;
    padding: 1em 0;
}

.info-container {
    display: flex;
    margin: 2em 0;
    flex-flow: row wrap;
}

.info-item {
    flex: 0 50%;
}

.info-item > h4 {
    font-size: 1.4em;
    font-weight: bold;
    margin: 1em 0;
}

.info-item p {
    font-size: 1.4em;
    margin: 0;
}

.products {
    margin: 2em 0;
}

.products > h4 {
    font-size: 1.3em;
    text-transform: uppercase;
    margin: 2em 0;
}

.products > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.products > table > thead tr th {
    text-align: center;
    font-size: 1.3em;
    font-weight: 200;
    padding: 0.5em 0;
    border-bottom: 3px solid #212121;
}

.products > table > tbody tr td {
    vertical-align: top;
    text-align: center;
    font-size: 1.3em;
    font-weight: 200;
    padding: 2em 0;
    border-bottom: 1px solid #212121;
}

.products > table > tbody tr td p:first-child {
    font-weight: bold;
}

.products > table > tbody > tr > td > div {
    display: inline-block;
    text-align: left;
}

.products > table > tfoot tr td {
    vertical-align: top;
    text-align: center;
    font-size: 1.3em;
    font-weight: 200;
    padding: 2em 0;
}

.products > table > tfoot tr:first-child td:last-child,
.products > table > tfoot tr:first-child td:nth-last-child(2) {
    border-top: 3px solid #212121;
}

.products > table > tfoot tr:nth-last-child(2) td {
    border-bottom: 3px solid #212121;
}

.products > table > tfoot tr:last-child td {
    border-bottom: 5px solid #212121;
}

.footer-link {
    display: flex;
    justify-content: flex-end;
    color: #212121;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 2em 0;
}

@media screen and (max-width: 767px) {
    .products > table,
    .products > table > tbody,
    .products > table > thead,
    .products > table > tfoot {
        display: block;
    }
    .products > table tr {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .products > table tfoot tr {
        grid-template-columns: 1fr 1fr;
    }
    .products > table td,
    .products > table th {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em 0 !important;
    }
    .products > table th {
        padding: 0.5em 0 !important;
    }
    .products > table th:empty,
    .products > table td:empty {
        display: none;
    }
}
